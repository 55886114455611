import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import PasswordChecklist from "react-password-checklist";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

import {
  CheckIcon,
  UserIcon,
  LockClosedIcon,
} from "@heroicons/react/24/outline";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import { observable, makeObservable } from "mobx";

import { observer, inject } from "mobx-react";

import Logo from "../images/wordwell-logo.png";
import { createNotification } from "../Core/Notification";

@inject("store")
@observer
class Login extends Component {
  @observable email = "";
  @observable password = "";
  @observable cPassword = "";
  @observable fname = "";
  @observable lname = "";
  @observable weddingRole = "";
  @observable weddingDate = "";
  @observable weddingLocation = "";

  @observable terms = false;
  @observable offers = false;
  @observable isPasswordValid = false;
  @observable isPasswordTyping = false;
  @observable isDisabled = false;
  @observable errorMessage = "";

  constructor() {
    super();
    makeObservable(this);
  }

  onChange = (val) => {
    this.currentPromptOption = val;
    console.log(this.currentPromptOption);
  };

  onTermsChange = (val) => {
    this.terms = val;
  };

  onOffersChange = (val) => {
    this.offers = val;
  };

  onChangeAny = (val, attr) => {
    this[attr] = val;
    this.errorMessage = "";
    if (attr === "password" || attr === "cPassword") {
      this.isPasswordTyping = true;
    }
  };

  onLogin = async (e) => {
    try {
      e.preventDefault();
      createNotification("info", "Processing", "Please wait, Logging in!");
      this.isDisabled = true;
      let data = await this.props.store.api
        .post("/auth/signin", {
          email: this.email,
          password: this.password,
        })
        .then(({ data }) => data);
      this.isDisabled = false;
      createNotification("success", "Success", "Logged in!");
      this.props.store.loginWithDataTokenAndProfile(data);
    } catch (err) {
      this.isDisabled = false;
      console.log(err);
      console.log(err?.response?.data?.message);
      if (err?.response?.data?.message) {
        this.errorMessage = err?.response?.data?.message;
        createNotification("danger", "Error!", this.errorMessage);
      }
    }
  };

  onSignup = async (e) => {
    try {
      e.preventDefault();
      createNotification(
        "info",
        "Processing",
        "Please wait, creating your account!"
      );
      this.isDisabled = true;
      this.errorMessage = "";
      console.log("signup");
      let data = await this.props.store.api
        .post("/auth/signup", {
          email: this.email,
          password: this.password,
          fname: this.fname,
          lname: this.lname,
          weddingRole: this.weddingRole,
          weddingDate: this.weddingDate,
          weddingLocation: this.weddingLocation,
          terms: this.terms,
          offers: this.offers,
          referral: this.props.store.referral,
        })
        .then(({ data }) => data);
      console.log(`onSignup`, data);
      if (data.status === "success") {
        // this.props.store.loginWithDataTokenAndProfile(data);
        this.props.history.push({
          pathname: "/confirm-account",
          state: { email: this.email, password: this.password },
        });
        this.isDisabled = false;
        createNotification(
          "success",
          "Success",
          "Verify your account. Confirmation code sent to your email address. Check junk/spam folder if not in inbox."
        );
      }
    } catch (err) {
      console.log(err);
      console.log(err?.response?.data?.message);
      if (err?.response?.data?.message) {
        this.errorMessage = err?.response?.data?.message;
        this.isDisabled = false;
        createNotification("danger", "Error!", this.errorMessage);
      }
    }
  };

  setPasswordValidation = (val) => {
    this.isPasswordValid = val;
  };
  // Currently Selected Input Option

  render() {
    return (
      <>
         <Helmet>
            <title>{this.props.location.pathname === "/login" ? "Login" : "Sign Up"} - Wordwell</title>
            <link rel="canonical" href={`https://app.wordwell.ai${this.props.location.pathname}`} />
            {this.props.location.pathname === "/login" || this.props.location.pathname === "/signup" ? (
            <meta name="robots" content="noindex, nofollow" />
            ) : null}
        </Helmet>
        <div className="container mx-auto lg:px-4 py-4 min-h-screen flex flex-col md:items-center md:justify-center">
          <div className="text-center mb-6">
            <img className="inline" src={Logo} width={250} alt="logo" />
          </div>
          <div
            className={`min-w-full md:min-w-0 bg-white rounded-xl shadow-xl transform transition-all  transition shadow-md hover:shadow-2xl focus:shadow-2xl w-1/2`}
          >
            <div className="align-bottom flex  transform transition-all sm:align-middle transition flex divide-x divide-gray-300 ">
              <NavLink
                to="/login"
                className={`flex-1 justify-center transition py-4 px-4 pr-8 rounded-t-md flex text-${
                  this.props.location.pathname === "/login"
                    ? "gray-800"
                    : "gray-600"
                } font-medium  bg-${
                  this.props.location.pathname === "/login"
                    ? "white"
                    : "gray-300"
                } hover:bg-${
                  this.props.location.pathname === "/login"
                    ? "white"
                    : "gray-100"
                } cursor-pointer`}
              >
                <div
                  className={`transition mr-4  flex-shrink-0 inline-flex items-center justify-center text-sm h-6 w-6 rounded-full bg-${
                    this.props.location.pathname === "/login"
                      ? "green-300"
                      : "gray-200"
                  } text-${
                    this.props.location.pathname === "/login" ? "green" : "gray"
                  }`}
                >
                  <CheckIcon
                    className={`transition h-4 w-4 text-${
                      this.props.location.pathname === "/login"
                        ? "green-600"
                        : "gray-400"
                    }`}
                    aria-hidden="true"
                  />
                </div>
                Login
              </NavLink>
              <NavLink
                to="/signup"
                className={`flex-1 justify-center transition py-4 px-4 pr-8 rounded-t-md flex text-${
                  this.props.location.pathname === "/signup"
                    ? "gray-800"
                    : "gray-600"
                } font-medium  bg-${
                  this.props.location.pathname === "/signup"
                    ? "white"
                    : "gray-300"
                } hover:bg-${
                  this.props.location.pathname === "/signup"
                    ? "white"
                    : "gray-100"
                } cursor-pointer`}
              >
                <div
                  className={`transition mr-4  flex-shrink-0 inline-flex items-center justify-center text-sm h-6 w-6 rounded-full bg-${
                    this.props.location.pathname === "/signup"
                      ? "green-300"
                      : "gray-200"
                  } text-${
                    this.props.location.pathname === "/signup"
                      ? "green"
                      : "gray"
                  }`}
                >
                  <CheckIcon
                    className={`transition h-4 w-4 text-${
                      this.props.location.pathname === "/signup"
                        ? "green-600"
                        : "gray-400"
                    }`}
                    aria-hidden="true"
                  />
                </div>
                Signup
              </NavLink>
            </div>
            <div className="px-4 py-4 md:px-12 md:py-12">
              {/* Sorru */}
              <Switch>
                <Route path="/login">
                  <Logon
                    landingPageUrl={this.props.store.landingPageUrl}
                    email={this.email}
                    password={this.password}
                    signUp={this.signUpWithGoogle}
                    isDisabled={this.isDisabled}
                    onChange={this.onChangeAny}
                    onLogin={this.onLogin}
                  />
                </Route>
                <Route path="/signup">
                  <Signup
                    email={this.email}
                    password={this.password}
                    cPassword={this.cPassword}
                    fname={this.fname}
                    lname={this.lname}
                    weddingRole={this.weddingRole}
                    weddingDate={this.weddingDate}
                    weddingLocation={this.weddingLocation}
                    terms={this.terms}
                    offers={this.offers}
                    isPasswordValid={this.isPasswordValid}
                    isPasswordTyping={this.isPasswordTyping}
                    isDisabled={this.isDisabled}
                    setPasswordValidation={this.setPasswordValidation}
                    onChange={this.onChangeAny}
                    onSignup={this.onSignup}
                    onTermsChange={this.onTermsChange}
                    onOffersChange={this.onOffersChange}
                  />
                </Route>
                <Route>
                  <Redirect to="/login" />
                </Route>
              </Switch>
            </div>
            <a
              href={`https://www.wordwell.ai/`}
              className="block text-center bg-gray-100  text-sm p-3 rounded-b-lg hover:bg-gray-200 cursor-pointer"
            >
              Back to Home Page
            </a>
          </div>
        </div>
      </>
    );
  }
}

const Logon = observer(
  ({
    active,
    email,
    password,
    onChange,
    onLogin,
    landingPageUrl,
    signUp,
    isDisabled,
  }) => {
    return (
      <>
        <form onSubmit={onLogin}>
          <div
            className={`mx-auto flex-shrink-0 flex items-center justify-center h-16 w-16 rounded-full bg-${
              email && password ? "green" : "gray"
            }-300  ${email && password ? "bg-green-300" : "bg-gray-300"} `}
          >
            <LockClosedIcon
              className={`h-8 w-8 ${active ? "text-green-700" : ""} text-${
                email && password ? "green-700" : "gray-500"
              }`}
              aria-hidden="true"
            />
          </div>
          <div className="mt-3 text-center ">
            <div className="text-3xl font-medium ">Log in</div>
            <p className="text-lg ">Login to your account</p>
            <div className="flex flex-col flex-1">
              <label className=" text-sm block mt-4 inline-block text-left">
                Email Address
              </label>
              <input
                value={email}
                onChange={(e) => onChange(e.target.value, "email")}
                focus="true"
                type="email"
                className="rounded-md text-lg px-4 py-2  border border-gray-300 "
                placeholder="john@smith.com"
              />
            </div>
            <div className="flex flex-col flex-1">
              <label className=" text-sm block mt-4 inline-block text-left">
                Password
              </label>
              <input
                value={password}
                onChange={(e) => onChange(e.target.value, "password")}
                type="password"
                className="rounded-md text-lg px-4 py-2  border border-gray-300 inline-block"
                placeholder="*******"
              />
            </div>
            <div className="flex flex-col">
              <button
                disabled={isDisabled}
                type="submit"
                className={`hover:bg-gray-600 font-medium rounded-lg text-lg px-4 py-2 bg-gray-500 text-white mt-4 border border-gray-300 inline-block ${
                  isDisabled && "pointer-events-none opacity-25"
                }`}
              >
                Log in
              </button>
              <NavLink to="/forgot" className="mt-4  text-sm">
                Forgot your password?
              </NavLink>
            </div>
          </div>
        </form>
      </>
    );
  }
);

const Signup = observer(
  ({
    active,
    email,
    password,
    cPassword,
    fname,
    lname,
    weddingRole,
    weddingDate,
    weddingLocation,
    terms,
    offers,
    onChange,
    onSignup,
    onTermsChange,
    onOffersChange,
    isPasswordValid,
    isPasswordTyping,
    setPasswordValidation,
    isDisabled,
  }) => {
    return (
      <>
        {/* onSignup */}
        <form onSubmit={onSignup}>
          <div
            className={`mx-auto flex-shrink-0 flex items-center justify-center h-16 w-16 rounded-full bg-${
              email && password && cPassword ? "green" : "gray"
            }-300  ${
              email && password && cPassword ? "bg-green-300" : "bg-gray-300"
            } `}
          >
            <UserIcon
              className={`h-8 w-8 ${active ? "text-green-700" : ""} text-${
                email && password && cPassword ? "green-700" : "gray-500"
              }`}
              aria-hidden="true"
            />
          </div>
          <div className="mt-3 text-center relative">
            <div className="text-3xl font-medium ">Sign Up</div>
            <p className="text-lg ">Create your account</p>
            <div className="md:flex">
              <div className="flex flex-col min-w-0 md:pr-2 flex-1">
                <label className=" text-sm block mt-4 inline-block text-left">
                  First Name
                </label>
                <input
                  value={fname}
                  onChange={(e) => onChange(e.target.value, "fname")}
                  type="text"
                  className="rounded-md text-lg px-4 py-2  border border-gray-300 inline-block w-auto"
                  placeholder="John"
                />
              </div>
              <div className="flex flex-col min-w-0 md:pl-2 flex-1">
                <label className=" text-sm block mt-4 inline-block text-left">
                  Last Name
                </label>
                <input
                  value={lname}
                  onChange={(e) => onChange(e.target.value, "lname")}
                  type="text"
                  className="rounded-md text-lg px-4 py-2  border border-gray-300 inline-block w-auto"
                  placeholder="Smith"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label className=" text-sm block mt-4 inline-block text-left">
                Email Address
              </label>
              <input
                value={email}
                onChange={(e) => onChange(e.target.value, "email")}
                focus="true"
                type="email"
                className="rounded-md text-lg px-4 py-2  border border-gray-300 "
                placeholder="john@smith.com"
                autoComplete="new-password"
              />
            </div>
            <div className="flex flex-col">
              <label className=" text-sm block mt-4 inline-block text-left">
                Password
              </label>
              <input
                value={password}
                onChange={(e) => onChange(e.target.value, "password")}
                type="password"
                className="rounded-md text-lg px-4 py-2  border border-gray-300 inline-block"
                placeholder="*******"
                autoComplete="new-password"
              />
            </div>
            <div className="flex flex-col">
              <label className=" text-sm block mt-4 inline-block text-left">
                Confirm Password
              </label>
              <input
                value={cPassword}
                onChange={(e) => onChange(e.target.value, "cPassword")}
                type="password"
                className="rounded-md text-lg px-4 py-2  border border-gray-300 inline-block"
                placeholder="*******"
              />
            </div>
            <div
              className={`absolute w-full mt-2 rounded-xl bg-white ${
                isPasswordTyping && !isPasswordValid ? "block" : "hidden"
              }`}
              style={{ boxShadow: "0 2px 15px 0 rgb(0 0 0 / 10%)" }}
            >
              <PasswordChecklist
                style={{ padding: "5px 10px" }}
                rules={[
                  "minLength",
                  "specialChar",
                  "number",
                  "capital",
                  "match",
                ]}
                minLength={5}
                value={password}
                valueAgain={cPassword}
                onChange={(isValid) => setPasswordValidation(isValid)}
              />
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="weddingRole"
                className="text-sm block mt-4 inline-block text-left"
              >
                Your Role in the Wedding
              </label>
              <select
                id="weddingRole"
                className="rounded-md text-lg px-4 py-2 border border-gray-300"
                value={weddingRole}
                onChange={(e) => onChange(e.target.value, "weddingRole")}
              >
                <option value="">Select your role</option>
                <option value="bestMan">Best Man</option>
                <option value="bride">Bride</option>
                <option value="father">Parent of the Bride</option>
                <option value="groom">Groom</option>
                <option value="maid">Maid of Honor</option>
                <option value="groomParents">Parent of the Groom</option>
                <option value="other">Other</option>
              </select>
            </div>
            <div className="flex flex-col">
              <label className="text-sm block mt-4 inline-block text-left">
                Wedding Date
              </label>
              <DatePicker
                className="rounded-md text-lg px-4 py-2 border border-gray-300 block w-full"
                selected={weddingDate}
                onChange={(date) => onChange(date, "weddingDate")}
                placeholderText="Select wedding date (if known)"
                minDate={new Date()}
              />
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="weddingRole"
                className="text-sm block mt-4 inline-block text-left"
              >
                Wedding Location
              </label>
              <GooglePlacesAutocomplete
                // apiKey={process.env.GOOGLE_PLACEAPI}

                selectProps={{
                  weddingLocation,
                  onChange: (loc) => onChange(loc.label, "weddingLocation"),
                  styles: {
                    option: (provided) => ({
                      ...provided,
                      textAlign: "left",
                    }),
                    placeholder: (provided) => ({
                      ...provided,
                      textAlign: "left",
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      textAlign: "left",
                    }),
                  },
                }}
              />
            </div>
            <div className=" text-sm text-left mt-4">
              <label>
                <input
                  id="terms"
                  type="checkbox"
                  value={terms}
                  onChange={(e) => onTermsChange(e.target.checked)}
                  className="mr-1 w-5 h-5 ease-soft align-middle"
                />
                <label htmlFor="terms" className="align-middle">
                  By signing up, I agree to the{" "}
                  <a
                    href={`https://www.wordwell.ai/privacy`}
                    target="_blank"
                    rel="noreferrer"
                    className="underline text-blue-700"
                  >
                    Privacy Policy
                  </a>{" "}
                  and the{" "}
                  <a
                    href={`https://www.wordwell.ai/terms-of-service`}
                    target="_blank"
                    rel="noreferrer"
                    className="underline text-blue-700"
                  >
                    Terms of Service.
                  </a>
                </label>
              </label>
              <br />
              <label>
                <input
                  id="offers"
                  type="checkbox"
                  value={offers}
                  onChange={(e) => onOffersChange(e.target.checked)}
                  className="mr-1 w-5 h-5 ease-soft align-middle"
                />
                <label htmlFor="offers" className="align-middle">
                Get your FREE speech delivery guide and occasional updates (we promise not to spam!)
                </label>
              </label>
            </div>
            <div className="flex flex-col">
              <button
                type="submit"
                disabled={isDisabled}
                className={`hover:bg-green-600 bg-green-500 font-medium rounded-lg text-lg px-4 py-2 bg-gray-200 text-white mt-4 border border-gray-300 inline-block ${
                  isDisabled && "pointer-events-none opacity-25"
                }`}
              >
                Sign Up
              </button>
            </div>
          </div>
        </form>
      </>
    );
  }
);

export default withRouter(Login);
