import React, { Component } from "react";
import styled from "styled-components";
import { observer, inject } from "mobx-react";
import { computed } from "mobx";
import { NavLink } from "react-router-dom";
import { UserCircleIcon } from "@heroicons/react/24/outline";

import { IconDashboard } from "./Icons";

import { withRouter } from "react-router-dom";

import Logo from "./images/wordwell-logo.png";

@inject("store")
@observer
class SidebarCompontent extends Component {
  constructor(props) {
    super(props);
    if (this.props.location.pathname === "/signup") {
      this.props.history.push("/");
    }
    if (this.props.location.pathname === "/login") {
      this.props.history.push("/");
    }
  }
  componentDidMount() {
    document.addEventListener("keydown", this.shortcutHandler);
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.shortcutHandler);
  }
  shortcutHandler = (e) => {
    if (e.keyCode === 75 && e.ctrlKey) {
      e.preventDefault();
      // select all text in input with id q
      document.getElementById("q").focus();
      document.getElementById("q").select();
    }
  };

  onKeyUp = (e) => {
    if (this.props.location.pathname !== "/search") {
      this.props.history.push("/search");
    }
    if (e.keyCode === 8) {
      if (this.props.store.toolsKeyword === "") {
        this.props.history.push("/");
      }
    }
  };

  @computed get fromColor() {
    if (this.props.store.profile.credits <= 0) {
      return "bg-red-200 text-red-600";
    }
    if (this.props.store.profile.status === "trialing") {
      return "";
    }
    if (this.props.store.profile.status === "active") {
      return "";
    }
    if (this.props.store.profile.status === "incomplete") {
      return "";
    }
    return "bg-red-200 text-red-600";
  }

  render() {
    return (
      <>
        <Textarea
          readOnly
          name="copy-textarea"
          id="copy-textarea"
          value={this.props.store.copyToClipboardText}
        />
        <div className="border-b border-gray-300 bg-white shadow-sm ">
          <div className="container flex mx-auto px-4 md:px-28 flex select-none items-center">
            <div className="flex flex-1 ">
              <div className="mr-4">
                <NavLink to="/">
                  <img src={Logo} width={150} alt="logo" />
                </NavLink>
              </div>
            </div>

            <NavLink
              to="/"
              exact
              tabIndex={-1}
              onClick={() => (this.props.store.toolsKeyword = "")}
              activeClassName="bg-gray-100 hover:bg-gray-200  transition"
              className="text-lg flex py-3 px-6 lg:py-4 lg:px-8 cursor-pointer hover:bg-gray-100 rounded-t-md font-medium transition items-center"
            >
              <IconDashboard className="w-7 h-7 lg:mr-4 transition" />
              <div className="hidden lg:block">Speech Dashboard</div>
            </NavLink>
            <NavLink
              to="/my-profile"
              exact
              tabIndex="-1"
              activeClassName="bg-green-100 hover:bg-green-200 text-green-800 transition"
              className={`text-lg flex py-3 px-6 xl:py-4 xl:px-8 cursor-pointer ${this.fromColor} hover:bg-gray-100 rounded-t-md font-medium transition items-center`}
            >
              <UserCircleIcon className="w-7 h-7 lg:mr-4 transition" />
              <div className="hidden lg:block"> Account settings</div>
            </NavLink>
          </div>
        </div>
        {this.props.children}
      </>
    );
  }
}

const Textarea = styled.textarea`
  position: fixed;
  right: -9990px;
  top: -9990px;
`;

export default withRouter(SidebarCompontent);
