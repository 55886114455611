import React, { Component } from "react";
import { observer, inject } from "mobx-react";

@inject("store")
@observer
class EntryPrompt extends Component {
  render() {
    const { currentPrompt, index } = this.props;
    let hidden = currentPrompt === index ? false : true;

    return (
      <div
        className={`align-bottom bg-white md:rounded-md text-left overflow-hidden transform transition-all sm:align-middle transition hover:shadow-md shadow-2xl focus:shadow-2xl md:mb-8  ${
          hidden ? "hidden" : ""
        }`}
      >
        <div className="py-6 px-6">
          <div className="flex items-center">
            <div className="mt-0 text-left">
              <p className="text-base ">
                {this.props.prompt.desc || "Write a small bit of text"}
              </p>
            </div>
          </div>
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default EntryPrompt;
