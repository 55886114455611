require("dotenv").config();

const config = {
  baseURL: process.env.REACT_APP_BASEURL,
  landingPageUrl: "#",
  stripe: {
    essentials: process.env.REACT_APP_STRIPE_ESSENTIALS,
    premium: process.env.REACT_APP_STRIPE_PREMIUM,
    premiumUpgrade: process.env.REACT_APP_STRIPE_PREMIUM_UPGRADE,
  },
};

export default config;
