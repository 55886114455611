import React from "react";
import { Link } from "react-router-dom";
import Loader from "./Loader";
import Typist from "react-typist";
import { CheckIcon } from "@heroicons/react/24/solid";
import {
  DocumentDuplicateIcon,
  DocumentArrowDownIcon,
  PaperAirplaneIcon,
  ArrowUturnDownIcon,
} from "@heroicons/react/24/outline";
import styled from "styled-components";
import { observer, inject } from "mobx-react";

import CodeEditor from "@uiw/react-textarea-code-editor";
import TextareaAutosize from "react-textarea-autosize";
import Button from "../Components/Button";
import { TypeAnimation } from "react-type-animation";

export const Output = inject("store")(
  observer(
    ({
      store,
      title,
      textTitle,
      toolType,
      speechTitle,
      desc,
      Icon,
      Image,
      output,
      code,
      language,
      outputs,
      loading,
      children,
      fromColor,
      toColor,
      outputsColor,
      OutputsIcon,
      onTypingDone,
      isTypingDone,
      printDocument,
      mailDocument,
      onOutputClick,
      isOutputEditing,
      canRemoveTypist,
      updateOutput,
      cancelUpdateOutput,
      onOutputChange,
      doUndo,
      doRedo,
      pdfTitle,
      plan,
      historyType,
      to,
    }) => {
      return (
        <div className="relative mb-12">
          <div className=" align-bottom bg-white md:rounded-3xl text-left  shadow-xl transform transition-all sm:align-middle transition shadow-md hover:shadow-2xl focus:shadow-2xl">
            <div className=" px-6 py-6">
              <div className="flex flex-wrap">
                <div className="sm:flex sm:items-start flex-auto">
                  {loading ? (
                    <>
                      <Loader active={loading} className="w-10 h-10" />
                    </>
                  ) : (
                    <>
                      <div
                        className={`mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-${
                          output ? "green" : "gray"
                        }-300 sm:mx-0 sm:h-10 sm:w-10 bg-gradient-to-r from-${
                          fromColor ? fromColor : "green-400"
                        } to-${toColor ? toColor : "blue-500"}`}
                      >
                        {Icon ? (
                          <Icon
                            className={`h-6 w-6 text-white`}
                            aria-hidden="true"
                          />
                        ) : null}
                        {Image ? (
                          <img src={Image} alt="logo" width={30} />
                        ) : null}
                      </div>
                    </>
                  )}

                  <div className="text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <div as="h3" className="text-lg leading-6 font-medium ">
                      {title}
                    </div>
                    {loading && (
                      <TypeAnimation
                        sequence={[
                          "It takes me about 1 minute to generate your speech. Trust me, it’s worth the wait.",
                          6000,
                          "Hang on, I'm loading up the good stuff.",
                          6000,
                          "Just a sec, I'm sorting through the information.",
                          6000,
                          "It takes me about 1 minute to generate your speech. Trust me, it’s worth the wait.",
                          6000,
                          "Hold tight, I'm working on it.",
                          6000,
                          "Hold on, I'm processing the data tsunami.",
                          6000,
                          "It takes me about 1 minute to generate your speech. Trust me, it’s worth the wait.",
                          6000,
                          "Hold tight, I'm processing your request with care.",
                          () => {},
                        ]}
                        wrapper="div"
                        cursor={true}
                        repeat={Infinity}
                        style={{ fontSize: "1em" }}
                      />
                    )}
                  </div>
                </div>
                {plan === "tbc"
                  ? ""
                  : isTypingDone && (
                      <div className="flex items-center">
                        <Shortcut className="p-1 m-1 rounded-lg cursor-pointer hover:bg-green-400 relative group flex flex-col items-center group bg-green-200 text-green-700">
                          <ArrowUturnDownIcon
                            onClick={doUndo}
                            className="w-8 h-8"
                          />
                          <Tooltip className="absolute bottom-2 flex flex-col items-center mb-6 group-hover:flex">
                            <span className="relative z-10 p-3 text-sm leading-none  bg-white bg-opacity-25 shadow-lg text-center backdrop-filter backdrop-blur rounded-md">
                              Undo Revision
                            </span>
                          </Tooltip>
                        </Shortcut>
                        <Shortcut className="p-1 m-1 rounded-lg cursor-pointer hover:bg-green-400 relative group flex flex-col items-center group bg-green-200 text-green-700">
                          <ArrowUturnDownIcon
                            style={{ transform: "scaleX(-1)" }}
                            className="w-8 h-8"
                            onClick={doRedo}
                          />
                          <Tooltip className="absolute bottom-2 flex flex-col items-center mb-6 group-hover:flex">
                            <span className="relative z-10 p-3 text-sm leading-none  bg-white bg-opacity-25 shadow-lg text-center backdrop-filter backdrop-blur rounded-md">
                              Redo Revision
                            </span>
                          </Tooltip>
                        </Shortcut>
                      </div>
                    )}
              </div>
              {plan === "tbc"
                ? ""
                : output && (
                    <div className="px-6 pt-1 text-xs ">
                      Click on text to edit.
                    </div>
                  )}

              {code ? null : output ? (
                <>
                  <div
                    id="divToPrint"
                    className="whitespace-pre-wrap min-w-full  h-auto text-lg divide-y px-4 pt-5 pb-4 sm:p-6 sm:pb-4"
                    onClick={plan === "tbc" ? undefined : onOutputClick}
                  >
                    {output ? (
                      <>
                        <div className="text-lg font-bold  text-center pb-2">
                          {pdfTitle}'s {speechTitle}
                        </div>
                        <div
                          className={`pt-2 ${
                            isOutputEditing ? "hidden" : "block"
                          }`}
                        >
                          {!canRemoveTypist ? (
                            <Typist
                              stdTypingDelay={0}
                              avgTypingDelay={7}
                              cursor={{
                                show: true,
                                blink: true,
                                element: "|",
                                hideWhenDone: true,
                                hideWhenDoneDelay: 250,
                              }}
                              onTypingDone={onTypingDone}
                            >
                              {plan === "tbc"
                                ? output.slice(0, (output.length * 30) / 100)
                                : output}
                            </Typist>
                          ) : (
                            <>
                              {plan === "tbc"
                                ? output.slice(0, (output.length * 30) / 100)
                                : output}
                            </>
                          )}
                          {plan === "tbc" && isTypingDone ? (
                            <div
                              style={{
                                height:
                                  historyType === "poem" ||
                                  historyType === "vows"
                                    ? "150px"
                                    : "300px",
                                display: "flex",
                                alignItems: "flex-end",
                                marginTop:
                                  historyType === "poem" ||
                                  historyType === "vows"
                                    ? "-150px"
                                    : "-300px",
                                backdropFilter: "blur(10px)",
                              }}
                            >
                              <Link
                                to={`/my-profile/pricing?redirect=${to}&type=${toolType}`}
                                className="w-full block inset-0 bg-gradient-to-r from-indigo-500 to-red-500 shadow-lg flex-1 rounded-md p-4 text-white font-medium text-center text-lg transition hover:from-gray-700 hover:to-gray-800 text-enter"
                              >
                                {`Upgrade To Instantly Access ${textTitle}`}
                              </Link>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </>
                    ) : null}
                  </div>
                  {isOutputEditing && output && isTypingDone && (
                    <TextareaWrapper>
                      <TextareaAutosize
                        value={output}
                        className={`textarea-autosize-scroll outline-none focus:outline-none text-lg bg-white rounded-md px-4 py-4  min-w-full border border-gray-300 font-regular focus:border-gray-400 border-gray-300 font-regular mt-2 `}
                        onChange={onOutputChange}
                      />
                      <Button title="Save updates" onClick={updateOutput} />
                      <span>
                        {" "}
                        <Button
                          title="Discard any changes"
                          onClick={cancelUpdateOutput}
                        />
                      </span>
                    </TextareaWrapper>
                  )}
                </>
              ) : null}

              {output && outputs && outputs.length ? (
                <div className="divide-y divide-dashed divide-gray-300">
                  {" "}
                  <div></div>
                  <div></div>
                </div>
              ) : null}

              {outputs && outputs.length ? (
                <Outputs
                  outputs={outputs}
                  outputsColor={outputsColor}
                  OutputsIcon={OutputsIcon}
                />
              ) : null}

              {code && code.length ? (
                <CodeEditor
                  style={{
                    fontFamily: "JetBrains Mono",
                    fontSize: "1rem",
                  }}
                  padding={30}
                  language={language}
                  value={code}
                />
              ) : null}
              {plan === "tbc"
                ? ""
                : output && (
                    <div className="px-6 py-1 text-xs ">
                      To revise your {textTitle}, return to the top of the page.
                    </div>
                  )}

              {plan === "tbc"
                ? ""
                : isTypingDone && (
                    <>
                      <QuickTools
                        outputs={outputs}
                        output={output}
                        code={code}
                        printDocument={printDocument}
                        mailDocument={mailDocument}
                        textTitle={textTitle}
                      />
                    </>
                  )}
            </div>
          </div>
        </div>
      );
    }
  )
);

export const QuickTools = inject("store")(
  observer(
    ({
      store,
      output,
      outputs,
      code,
      printDocument,
      mailDocument,
      textTitle,
    }) => {
      return (
        <>
          {output || code || (outputs && outputs.length) ? (
            <>
              <div className="px-6 pb-1 text-xs ">
              Finished? Choose your export option below.
              </div>
              <div className="flex">
                <Shortcut
                  className="p-1 m-1 rounded-lg cursor-pointer hover:bg-green-400 relative group flex flex-col items-center group bg-green-200 text-green-700"
                  onClick={() =>
                    store.copyToClipboard(output || code || outputs)
                  }
                >
                  <DocumentDuplicateIcon className="w-8 h-8" />
                  <Tooltip className="absolute bottom-2 flex flex-col items-center mb-6 group-hover:flex">
                    <span className="relative z-10 p-3 text-sm leading-none  bg-white bg-opacity-25 shadow-lg text-center backdrop-filter backdrop-blur rounded-md">
                      Copy text to clipboard
                    </span>
                  </Tooltip>
                </Shortcut>
                {/* <Shortcut
                  className="p-1 m-1 rounded-lg cursor-pointer hover:bg-green-400 relative group flex flex-col items-center group bg-green-200 text-green-700"
                  onClick={printDocument}
                >
                  <DocumentArrowDownIcon className="w-8 h-8" />
                  <Tooltip className="absolute bottom-2 flex flex-col items-center mb-6 group-hover:flex">
                    <span className="relative z-10 p-3 text-sm leading-none  bg-white bg-opacity-25 shadow-lg text-center backdrop-filter backdrop-blur rounded-md">
                      Download PDF
                    </span>
                  </Tooltip>
                </Shortcut> */}
                <Shortcut
                  className="p-1 m-1 rounded-lg cursor-pointer hover:bg-green-400 relative group flex flex-col items-center group bg-green-200 text-green-700"
                  onClick={mailDocument}
                >
                  <PaperAirplaneIcon className="w-8 h-8" />
                  <Tooltip className="absolute bottom-2 flex flex-col items-center mb-6 group-hover:flex">
                    <span className="relative z-10 p-3 text-sm leading-none  bg-white bg-opacity-25 shadow-lg text-center backdrop-filter backdrop-blur rounded-md">
                      Send to my email
                    </span>
                  </Tooltip>
                </Shortcut>
              </div>
            </>
          ) : null}
        </>
      );
    }
  )
);

const Tooltip = styled.div`
  display: none;
  white-space: nowrap;
`;

const Shortcut = styled.div`
  &:hover ${Tooltip} {
    display: flex;
  }
`;

const TextareaWrapper = styled.div`
  & ::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }
  & ::-webkit-scrollbar-thumb {
    background-color: #6e6e6e;
    outline: #444 solid 1px;
  }
  & ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
`;

function Outputs({ outputs, outputsColor, OutputsIcon }) {
  return (
    <div className="whitespace-pre-wrap min-w-full py-4  h-auto text-lg divide-y">
      <Typist
        stdTypingDelay={0}
        avgTypingDelay={7}
        className="divide-y"
        cursor={{
          show: false,
          blink: false,
          element: "|",
          hideWhenDone: true,
          hideWhenDoneDelay: 250,
        }}
      >
        {outputs.map((output, index) => (
          <div className="py-2 flex items-start" key={index}>
            <div
              className={`mr-4 flex-shrink-0 inline-flex items-center justify-center text-sm h-6 w-6 rounded-full bg-${
                outputsColor ? outputsColor : "green"
              }-200 text-${outputsColor ? outputsColor : "green"}-600`}
            >
              {OutputsIcon === false ? (
                `${index + 1}`
              ) : OutputsIcon ? (
                <OutputsIcon
                  className={`h-4 w-4 text-${
                    outputsColor ? outputsColor : "green"
                  }-600`}
                  aria-hidden="true"
                />
              ) : (
                <CheckIcon
                  className={`h-4 w-4 text-${
                    outputsColor ? outputsColor : "green"
                  }-600`}
                  aria-hidden="true"
                />
              )}
            </div>
            {output}
          </div>
        ))}
      </Typist>
    </div>
  );
}

export default Output;
