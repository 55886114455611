import React, { Component } from "react";
import { Link } from "react-router-dom";
import { observable, makeObservable, computed, action } from "mobx";
import MainBody from "./Components/Body";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import {
  DocumentDuplicateIcon,
  DocumentArrowDownIcon,
  PaperAirplaneIcon,
} from "@heroicons/react/24/outline";
import { createNotification } from "./Core/Notification";
import jsPDF from "jspdf";
import { observer, inject } from "mobx-react";
import Guide from "./images/speech-guide.pdf";
import Clip from "./images/clip-icon.png";

@inject("store")
@observer
class Body extends Component {
  @observable initialData = [];
  @observable isInitialDataloading = true;

  constructor(props) {
    super(props);
    makeObservable(this);
    this.loadData();
  }

  @action async loadData() {
    let response = await this.props.store.api.get("/ai/speech");
    if (response) {
      this.isInitialDataloading = false;
      if (response.data.data) {
        this.initialData = response.data.data;
      }
    }
  }

  findTitle = (type) => {
    let tool = this.props.store.tools.find((t) => t.historyType === type);
    return tool ? tool.title : "";
  };

  printDocument = (d) => {
    createNotification("info", "Processing", "Please wait!");
    let htmlElement = `
    <div style="
    width:550px; 
    padding: 1.5rem;
    color: #1f2937;
    ">
    
    <div style="
    font-weight: 700; 
    font-size: 1.125rem;
    line-height: 1.75rem; 
    text-align: center; 
    padding-bottom: 0.5rem;
    border-bottom-width: 1px;">
    ${d.data.name}'s&nbsp; ${this.findTitle(d.historyType)}
    </div>

    <div style="    
    font-size: 1.125rem;
    line-height: 1.75rem;">${d.output.split("\n").join("<br />")}</div>

    </div>
    `;

    let fileName = "speech.pdf";

    if (d.historyType === "poem") {
      fileName = "message-or-poem.pdf";
    } else if (d.historyType === "vows") {
      fileName = "wedding-vows.pdf";
    }

    let pdf = new jsPDF("p", "pt", "a4");
    pdf.html(htmlElement, {
      callback: function (pdf) {
        pdf.save(fileName);
        // window.open(pdf.output("bloburl"));
      },
      autoPaging: "text",
      margin: 20,
      html2canvas: { scale: 1 },
    });
  };

  mailDocument = async (d) => {
    try {
      createNotification("info", "Processing", "Please wait!");
      let postObj = {
        output: d.output,
        historyType: d.historyType,
      };

      let response = await this.props.store.api.post(
        "/ai/wedding/email",
        postObj
      );

      if (response.data.status === "success") {
        createNotification(
          "success",
          "Success!",
          "Mail has been sent to your account!"
        );
      }

      if (!response.data.success) {
        return false;
      }
    } catch (error) {
      console.log(error);
    }
  };

  @computed get content() {
    return this.props.store.tools;
  }

  render() {
    return (
      <>
        <Helmet>
          <title>{`Speech Dashboard - Prepare and create your own OpenAI Prompts and Outputs`}</title>
        </Helmet>
        
          <MainBody className="px-4 py-4 md:px-28 md:py-8 lg:py-12 ">
          {
          /*<Title title="Platform Tutorial" />
          <div className="text-base ">
            We highly recommend watching our{" "}
            <Link className="underline" to="/tutorial">
              3-minute video
            </Link>{" "}
            guide before getting started. It's easy to follow and covers
            everything you need to know to create the perfect toast using this
            platform
          </div>
          <Divider />
          */
          }         
          {this.content.length ? (
            <>
              <Title title="Select your preferred option" subTitle="Speeches" />

              <Grid>
                {this.content.map((tool, index) =>
                  tool.toolType === "speech" ? (
                    <Tool
                      key={index}
                      title={tool.title}
                      to={tool.to}
                      activeType={this.props.store.profile.activeType}
                      addonActiveType={this.props.store.profile.addonActiveType}
                      historyType={tool.historyType}
                      toolType={tool.toolType}
                      icon={tool.icon}
                      plan={this.props.store.profile.plan}
                    />
                  ) : (
                    ""
                  )
                )}
              </Grid>
              <div className="py-3"></div>
            </>
          ) : null}
          {this.content.length ? (
            <>
              <Title subTitle="Vows & Poems" />

              <Grid>
                {this.content.map((tool, index) =>
                  tool.toolType === "addon" ? (
                    <Tool
                      key={index}
                      title={tool.title}
                      to={tool.to}
                      activeType={this.props.store.profile.activeType}
                      addonActiveType={this.props.store.profile.addonActiveType}
                      historyType={tool.historyType}
                      toolType={tool.toolType}
                      icon={tool.icon}
                      plan={this.props.store.profile.plan}
                    />
                  ) : (
                    ""
                  )
                )}
              </Grid>
              <Divider />
            </>
          ) : null}
          {!this.isInitialDataloading && (
            <>
              <Title title="My Saved Speeches" />
              <Grid>
                {/* {this.content.map((tool, index) => (
                  <Tools
                    key={index}
                    pre={this.initialData.data.name}
                    title={tool.title}
                    output={this.initialData.output}
                    store={this.props.store}
                    printDocument={() => this.printDocument(tool.title)}
                    mailDocument={this.mailDocument}
                  />
                ))} */}
                {this.initialData.map(
                  (d, index) =>
                    d.output && (
                      <Tools
                        key={index}
                        pre={d.data.name}
                        title={this.findTitle(d.historyType)}
                        output={d.output}
                        store={this.props.store}
                        printDocument={() => this.printDocument(d)}
                        mailDocument={() => this.mailDocument(d)}
                      />
                    )
                )}
              </Grid>
              <Divider />
            </>
          )}
          {(this.props.store.profile.plan === "entry" ||
            this.props.store.profile.plan === "pro") && (
            <>
              <Title title="Wedding Speech Delivery Guide" />
              <div className="text-base ">
                This handy guide contains valuable tips to help you refine,
                prepare, and confidently deliver your speech.
              </div>
              <a href={Guide} target="_blank" rel="noreferrer">
                <img
                  src={Clip}
                  alt="pdf-icon"
                  width="100px"
                  style={{ cursor: "pointer" }}
                />
              </a>
              <Divider />
            </>
          )}
        </MainBody>
      </>
    );
  }
}

export const Divider = () => (
  <div className="divide-y-2 divide-dashed divide-gray-300 py-8 md:py-12">
    {" "}
    <div></div>
    <div></div>
  </div>
);

export const Title = ({ title, subTitle }) => (
  <div className="mb-4 md:mb-6">
    <h2 className="text-xl sm:text-2xl md:text-3xl ">{title}</h2>
    {subTitle && <h3 className="text-lg ">{subTitle}</h3>}
  </div>
);

export const Grid = ({ children }) => (
  <div className="grid grid-cols-1 gap-8 mt-4 lg:grid-cols-2 xl:grid-cols-3 ">
    {children}
  </div>
);

export const Tool = ({
  title,
  to,
  historyType,
  activeType,
  addonActiveType,
  toolType,
  icon,
  plan,
}) => (
  <Link
    to={
      (activeType === "inactive" && addonActiveType === "inactive") ||
      (activeType === "inactive" && plan === "pro" && toolType !== "addon") ||
      (addonActiveType === "inactive" &&
        plan === "pro" &&
        toolType === "addon") ||
      (activeType === historyType && toolType !== "addon") ||
      (addonActiveType === historyType && toolType === "addon")
        ? to || "/"
        : "/"
    }
    className={`flex relative ${
      (activeType === "inactive" && addonActiveType === "inactive") ||
      (activeType === "inactive" && plan === "pro" && toolType !== "addon") ||
      (addonActiveType === "inactive" &&
        plan === "pro" &&
        toolType === "addon") ||
      (activeType === historyType && toolType !== "addon") ||
      (addonActiveType === historyType && toolType === "addon")
        ? ""
        : "opacity-50 cursor-none"
    }`}
  >
    <div
      className={`bg-white flex-1 rounded-xl transition hover:shadow-md overflow-hidden md:max-w-1lg  cursor-pointer border border-gray-300 md:flex relative transform hover:scale-105  `}
    >
      <div className="p-4">
        <div
          href="#"
          className="flex items-center text-lg xl:text-xl 2xl:text-2xl leading-tight font-medium  leading-none"
        >
          <img className="mr-2" src={icon} alt="icon" />
          {title}
        </div>
      </div>
    </div>
  </Link>
);

export const Tools = ({
  store,
  title,
  pre,
  output,
  printDocument,
  mailDocument,
}) => (
  <Link to="/" className="flex relative ">
    <div
      className={`bg-white flex-1 rounded-xl transition hover:shadow-md md:max-w-1lg  cursor-pointer border border-gray-300 md:flex relative transform hover:scale-105  `}
    >
      <div className="p-4 w-full">
        <div
          href="#"
          className="block text-lg xl:text-xl 2xl:text-2xl leading-tight font-medium  leading-none border-b border-solid border-slate-200 pb-2"
        >
          {pre && pre + `'s `}
          {title}
        </div>
        <div className="py-1 text-xs ">
          Click on your preferred option to export your speech.
        </div>
        <div className="flex">
          <Shortcut
            className="p-1 m-1 rounded-lg cursor-pointer hover:bg-green-400 relative group flex flex-col items-center group bg-green-200 text-green-700"
            onClick={() => store.copyToClipboard(output)}
          >
            <DocumentDuplicateIcon className="w-8 h-8" />
            <Tooltip className="absolute bottom-2 flex flex-col items-center mb-6 group-hover:flex">
              <span className="relative z-10 p-3 text-sm leading-none  bg-white bg-opacity-25 shadow-lg text-center backdrop-filter backdrop-blur rounded-md">
                Copy text to clipboard
              </span>
            </Tooltip>
          </Shortcut>
          {/* <Shortcut
            className="p-1 m-1 rounded-lg cursor-pointer hover:bg-green-400 relative group flex flex-col items-center group bg-green-200 text-green-700"
            onClick={printDocument}
          >
            <DocumentArrowDownIcon className="w-8 h-8" />
            <Tooltip className="absolute bottom-2 flex flex-col items-center mb-6 group-hover:flex">
              <span className="relative z-10 p-3 text-sm leading-none  bg-white bg-opacity-25 shadow-lg text-center backdrop-filter backdrop-blur rounded-md">
                Download PDF
              </span>
            </Tooltip>
          </Shortcut> */}
          <Shortcut
            className="p-1 m-1 rounded-lg cursor-pointer hover:bg-green-400 relative group flex flex-col items-center group bg-green-200 text-green-700"
            onClick={mailDocument}
          >
            <PaperAirplaneIcon className="w-8 h-8" />
            <Tooltip className="absolute bottom-2 flex flex-col items-center mb-6 group-hover:flex">
              <span className="relative z-10 p-3 text-sm leading-none  bg-white bg-opacity-25 shadow-lg text-center backdrop-filter backdrop-blur rounded-md">
                Send to my email
              </span>
            </Tooltip>
          </Shortcut>
        </div>
      </div>
    </div>
  </Link>
);

export default Body;

const Tooltip = styled.div`
  display: none;
  white-space: nowrap;
`;

const Shortcut = styled.div`
  &:hover ${Tooltip} {
    display: flex;
  }
`;
