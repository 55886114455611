import icon from "../images/icons/maid.png";

const obj = {
  title: "Maid of Honor",
  to: "/ai/wedding/maid",
  api: "/ai/wedding",
  getApi: "/ai/wedding/maid",
  historyType: "maid",
  toolType: "speech",
  buttonTitle1: "Draft my speech",
  buttonTitle2: "Refine my speech",
  textTitle: "Speech",
  output: {
    title: "Speech Preview",
    Icon: false,
    color: "blue",
  },
  icon: icon,
  prompts: [
    {
      title: "Step 1: Provide Key Information",
      id: "information",
      desc: "Answer questions about the couple to get a custom-written draft toast. Share fun stories and specific details for the best results.",
      // n: 1,
      instruction: `<!-- <h1>Craft a memorable toast in a few simple steps:</h1> --> 
      <li>Get your draft: Answer questions.</li> 
      <li>Make it yours: Give feedback or tap speech to edit.</li> 
      <li>Ready to share: Export for the big day.</li>`,
      prompts: [
        {
          title: "Maid of Honor's name",
          attr: "name",
          value: "",
          //placeholder: "My name is...",
          label: "",
          type: "text",
          maxLength: 40,
          required: true,
          error: "",
        },
        {
          title: "Bride's name",
          attr: "brideName",
          value: "",
          //placeholder: "The Bride's name is...",
          label: "",
          type: "text",
          maxLength: 40,
          required: true,
          error: "",
        },
        {
          title: "Bride's partner",
          attr: "partnerName",
          value: "",
          //placeholder: "The Bride's partner's name is...",
          label: "",
          type: "text",
          maxLength: 40,
          required: true,
          error: "",
        },
        {
          title: "What tone are you aiming for in your speech? (E.g. Heartfelt, funny, formal, or a blend)",
          attr: "tone",
          value: "",
          //placeholder: "I would like the speech to be...",
          //label: "Examples: sentimental and heartfelt, lighthearted and fun",
          type: "text",
          maxLength: 100,
          required: true,
          error: "",
        },
        //{
          //title: "What is your relationship to the Bride?",
          //attr: "brideRelationship",
          //value: "",
          //placeholder: "I am......",
          //label: "Examples: Sister, best friend, lifelong friend",
          //type: "text",
          //maxLength: 100,
          //required: true,
          //error: "",
        //},
        {
          title: "Three words that describe the Bride",
          attr: "brideWords",
          value: "",
          //placeholder: "The Bride is...",
          label: "",
          type: "text",
          maxLength: 100,
          required: true,
          error: "",
        },
        {
          title:
            "How did you meet the Bride? Share a favourite memory",
          attr: "brideMeet",
          value: "",
          //placeholder: "I have known the Bride...",
          label: "",
          type: "textarea",
          maxLength: 400,
          required: false,
          error: "",
        },
        {
          title: "Describe your special bond with the Bride",
          attr: "brideBond",
          value: "",
          //placeholder: "The bond between the Bride and I...",
          label: "",
          type: "textarea",
          maxLength: 400,
          required: false,
          error: "",
        },
        {
          title: "What are the Bride's best qualities? Can you think of a time they really shone through?",
          attr: "brideMemorable",
          value: "",
          //placeholder: "A memorable moment...",
          label: "",
          type: "textarea",
          maxLength: 400,
          required: false,
          error: "",
        },
        //{
          //title:
            //"Describe an obstacle the Bride overcame or a turning point in her life",
          //attr: "brideMilestone",
          //value: "",
          //placeholder: "A significant event...",
          //label: "",
          //type: "textarea",
          //maxLength: 400,
          //required: true,
          //error: "",
        //},
        {
          title:
            "What are some specific achievements by the Bride that you would like to acknowledge?",
          attr: "brideAchievement",
          value: "",
          //placeholder: "A major achievement...",
          label: "",
          type: "textarea",
          maxLength: 400,
          required: false,
          error: "",
        },
        {
          title: "Three words that describe the Bride's partner",
          attr: "partnerWords",
          value: "",
          //placeholder: "The Bride's partner is...",
          label: "",
          type: "text",
          maxLength: 100,
          required: true,
          error: "",
        },
        {
          title:
            "Describe her partner's best qualities, and how do they bring out the best in her?",
          attr: "partnerImpact",
          value: "",
          //placeholder: "The Bride's partner...",
          label: "",
          type: "textarea",
          maxLength: 400,
          required: false,
          error: "",
        },
        {
          title:
            "Why are the Bride and her partner such a great match? What's a moment that showed you this?",
          attr: "coupleSpecial",
          value: "",
          //placeholder: "The couple are...",
          label: "",
          type: "textarea",
          maxLength: 400,
          required: false,
          error: "",
        },
        {
          title:
            "Describe a time when the couple faced something together, showcasing their love, strength, or connection",
          attr: "coupleTraditions",
          value: "",
          //placeholder: "The couple are...",
          label: "",
          type: "textarea",
          maxLength: 400,
          required: false,
          error: "",
        },
        {
          title:
            "What are your hopes and dreams for the couple's life together?",
          attr: "acknowledgeGuests",
          value: "",
          //placeholder: "I would like to...",
          label: "",
          type: "textarea",
          maxLength: 400,
          required: false,
          error: "",
        },
        {
          title:
            "Is there anything else you would like to include in your speech?",
          attr: "coupleWisdom",
          value: "",
          //placeholder: "I would like to...",
          label: "",
          type: "textarea",
          maxLength: 600,
          required: false,
          error: "",
        },
      ],
    },
    {
      title: "Step 2: Refine Speech",
      id: "refine",
      desc: "Tweak your speech for the perfect fit. Adjust the tone, add stories, or suggest any edits you want. Your speech will update instantly.",
      instruction: "Number of automated revisions remaining:",
      prompts: [
        {
          title:
            "What changes would you like?",
          attr: "feedbackdescription",
          value: "",
          //placeholder: "Please update the...",
          label: "",
          type: "textarea",
          minRows: 5,
          maxLength: 800,
          required: true,
          error: "",
        },
      ],
    },
  ],
};

export default obj;
