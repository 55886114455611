import React, { Component } from "react";
import { NavLink } from "react-router-dom";

import { LockClosedIcon } from "@heroicons/react/24/outline";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import { observable, makeObservable } from "mobx";

import { observer, inject } from "mobx-react";

import Logo from "../images/wordwell-logo.png";
import { createNotification } from "../Core/Notification";

@inject("store")
@observer
class Reset extends Component {
  @observable email = "";
  @observable otp = "";
  @observable password = "";
  @observable confirmPassword = "";
  @observable errorMessage = "";
  @observable isDisabled = false;

  constructor(props) {
    super(props);
    makeObservable(this);
    this.email = this.props.location.state.email;
  }

  onChange = (val, attr) => {
    this[attr] = val;
    this.errorMessage = "";
  };

  onReset = async (e) => {
    try {
      e.preventDefault();
      this.isDisabled = true;
      createNotification("info", "Processing", "Please wait!");
      if (!this.email || !this.password || !this.confirmPassword || !this.otp) {
        this.errorMessage = "Please submit all details!";
        createNotification("danger", "Error!", this.errorMessage);
        this.isDisabled = false;
      } else if (this.otp.length !== 6) {
        this.errorMessage = "Please enter 6 digit OTP!";
        createNotification("danger", "Error!", this.errorMessage);
        this.isDisabled = false;
      } else if (this.password !== this.confirmPassword) {
        this.errorMessage = "Sorry, but the passwords do not match!";
        createNotification("danger", "Error!", this.errorMessage);
        this.isDisabled = false;
      } else {
        let data = await this.props.store.api
          .post("/auth/reset", {
            email: this.email,
            password: this.password,
            otp: this.otp,
          })
          .then(({ data }) => data);
        this.isDisabled = false;
        if (data.token && data.profile) {
          await this.props.store.loginWithDataTokenAndProfile(data);
          this.props.history.push("/");
        } else if (data.status === "incorrectOtp") {
          createNotification(
            "danger",
            "Error!",
            "Sorry, Your OTP code is incorrect"
          );
        } else if (data.status === "notExists") {
          this.props.history.push("/signup");
        } else if (data.status === "expiredOtp") {
          this.props.history.push("/forgot");
        }
      }
    } catch (err) {
      this.isDisabled = false;
      console.log(err);
      console.log(err?.response?.data?.message);
      if (err?.response?.data?.message) {
        this.errorMessage = err?.response?.data?.message;
        createNotification("danger", "Error!", this.errorMessage);
      }
    }
  };

  render() {
    return (
      <>
        <Helmet>
          <title>{`Reset - Prepare and create your own OpenAI Prompts and Outputs`}</title>
        </Helmet>
        <div className="container mx-auto lg:px-4 py-4 min-h-screen flex flex-col md:items-center md:justify-center">
          <div className="text-center mb-6">
            <img className="inline" src={Logo} width={250} alt="logo" />
          </div>

          <div
            className={`min-w-full md:min-w-0 bg-white rounded-xl shadow-xl transform transition-all transition shadow-md hover:shadow-2xl focus:shadow-2xl w-1/2`}
          >
            <div className="px-4 py-4 md:px-12 md:py-12">
              <ResetBlock
                email={this.email}
                otp={this.otp}
                password={this.password}
                confirmPassword={this.confirmPassword}
                onReset={this.onReset}
                onChange={this.onChange}
                isDisabled={this.isDisabled}
              />
            </div>
            <a
              href={`https://www.wordwell.ai/`}
              className="block text-center bg-gray-100  text-sm p-3 rounded-b-lg hover:bg-gray-200 cursor-pointer"
            >
              Back to Home Page
            </a>
          </div>
        </div>
      </>
    );
  }
}

const ResetBlock = observer(
  ({
    email,
    otp,
    password,
    confirmPassword,
    onReset,
    onChange,
    isDisabled,
  }) => {
    return (
      <>
        <form onSubmit={onReset}>
          <div
            className={`mx-auto flex-shrink-0 flex items-center justify-center h-16 w-16 rounded-full bg-${
              email & otp ? "green" : "gray"
            }-300  ${email && otp ? "bg-green-300" : "bg-gray-300"} `}
          >
            <LockClosedIcon
              className={`h-8 w-8 text-${
                email & otp ? "green-700" : "gray-500"
              }`}
              aria-hidden="true"
            />
          </div>
          <div className="mt-3 text-center ">
            <div className="text-3xl font-medium ">Reset</div>
            <p className="text-lg ">Reset Password</p>
            <div className="flex flex-col flex-1">
              <label className=" text-sm block mt-4 inline-block text-left">
                Email Address
              </label>
              <input
                value={email}
                onChange={(e) => onChange(e.target.value, "email")}
                focus="true"
                type="email"
                className="rounded-md text-lg px-4 py-2 border border-gray-300 "
                placeholder="john@smith.com"
              />
            </div>
            <div className="flex flex-col flex-1">
              <label className=" text-sm block mt-4 inline-block text-left">
                One-Time Password
              </label>
              <input
                value={otp}
                onChange={(e) => onChange(e.target.value, "otp")}
                focus="true"
                type="number"
                className="rounded-md text-lg px-4 py-2 border border-gray-300 "
                placeholder="123456"
              />
              <div className=" text-sm block text-left">
                Check your email for a one-time password from us. Can't find it?
                Check your spam or junk folder.
              </div>
            </div>
            <div className="flex flex-col flex-1">
              <label className=" text-sm block mt-4 inline-block text-left">
                Password
              </label>
              <input
                value={password}
                onChange={(e) => onChange(e.target.value, "password")}
                focus="true"
                type="password"
                className="rounded-md text-lg px-4 py-2 border border-gray-300 "
              />
            </div>
            <div className="flex flex-col flex-1">
              <label className=" text-sm block mt-4 inline-block text-left">
                Confirm Password
              </label>
              <input
                value={confirmPassword}
                onChange={(e) => onChange(e.target.value, "confirmPassword")}
                focus="true"
                type="password"
                className="rounded-md text-lg px-4 py-2 border border-gray-300 "
              />
            </div>
            <div className="flex flex-col">
              <button
                disabled={isDisabled}
                type="submit"
                className={`hover:bg-gray-600 font-medium rounded-lg text-lg px-4 py-2 bg-gray-500 text-white mt-4 border border-gray-300 inline-block ${
                  isDisabled && "pointer-events-none opacity-25"
                }`}
              >
                Reset
              </button>
              <NavLink to="/login" className="mt-4  text-sm">
                Login to your account
              </NavLink>
            </div>
          </div>
        </form>
      </>
    );
  }
);

export default withRouter(Reset);
