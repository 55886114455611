import icon from "../images/icons/bride.png";

const obj = {
  title: "Bride",
  to: "/ai/wedding/bride",
  api: "/ai/wedding",
  getApi: "/ai/wedding/bride",
  historyType: "bride",
  toolType: "speech",
  buttonTitle1: "Draft my speech",
  buttonTitle2: "Refine my speech",
  textTitle: "Speech",
  output: {
    title: "Speech Preview",
    Icon: false,
    color: "blue",
  },
  icon: icon,

  prompts: [
    {
      title: "Step 1: Provide Key Information",
      id: "information",
      desc: "Answer questions to get a custom-written draft toast. Share fun stories and specific details for the best results.",
      // n: 1,
      instruction: `<!-- <h1>Craft a memorable toast in a few simple steps:</h1> -->
      <li>Get your draft: Answer questions.</li> 
      <li>Make it yours: Give feedback or tap speech to edit.</li> 
      <li>Ready to share: Export for the big day.</li>`,
      prompts: [
        {
          title: "Bride's name",
          attr: "name",
          value: "",
          //placeholder: "My name is...",
          label: "",
          type: "text",
          maxLength: 40,
          required: true,
          error: "",
        },
        {
          title: "Bride's partner's name",
          attr: "partnerName",
          value: "",
          //placeholder: "My partner’s name is...",
          label: "",
          type: "text",
          maxLength: 40,
          required: true,
          error: "",
        },
        {
          title: "Names of the Bride’s parents",
          attr: "brideParents",
          value: "",
          //placeholder: "My parents’ names are...",
          label: "",
          type: "text",
          maxLength: 100,
          required: false,
          error: "",
        },
        {
          title: "Names of the partner's parents",
          attr: "partnerParents",
          value: "",
          //placeholder: "My partner’s parents’ names are...",
          label: "",
          type: "text",
          maxLength: 100,
          required: false,
          error: "",
        },
        {
          title: "What tone are you aiming for in your speech? (E.g. Heartfelt, funny, formal, or a blend)",
          attr: "tone",
          value: "",
          //placeholder: "I would like the speech to be...",
          //label:
            //"Example: lighthearted and humorous, sentimental and heartfelt",
          type: "text",
          maxLength: 100,
          required: true,
          error: "",
        },
        {
          title: "Three words that describe your partner",
          attr: "partnerWords",
          value: "",
          //placeholder: "My partner is...",
          label: "",
          type: "text",
          maxLength: 100,
          required: true,
          error: "",
        },
        {
          title: "What initially attracted you to your partner? When did you realize you were in love?",
          attr: "partnerMeet",
          value: "",
          //placeholder: "I have known my partner...",
          label: "",
          type: "textarea",
          maxLength: 400,
          required: false,
          error: "",
        },
        {
          title:
            "Describe your partner's best qualities. Can you share a moment when these qualities truly shone through?",
          attr: "partnerSpecial",
          value: "",
          //placeholder: "My partner is...",
          label: "",
          type: "textarea",
          maxLength: 400,
          required: false,
          error: "",
        },
        {
          title:
            "How has your life changed since meeting your partner? What are your hopes for your future together?",
          attr: "partnerMemorable",
          value: "",
          //placeholder: "A memorable moment...",
          label: "",
          type: "textarea",
          maxLength: 400,
          required: false,
          error: "",
        },
        {
          title:
            "Can you think of a time when you faced something together as a couple that truly highlighted your love, strength, or connection?",
          attr: "coupleMilestone",
          value: "",
          //placeholder: "A significant achievement...",
          label: "",
          type: "textarea",
          maxLength: 400,
          required: false,
          error: "",
        },
        {
          title:
            "Is there anyone else you'd like to acknowledge, such as  parents, in-laws, friends or family members?",
          attr: "guestAcknowledge",
          value: "",
          //placeholder: "I would like to...",
          label: "",
          type: "textarea",
          maxLength: 400,
          required: false,
          error: "",
        },
        {
          title:
            "Do you have any special promises or words of love you'd like to express directly to your partner?",
          attr: "partnerShare",
          value: "",
          //placeholder: "I would like to...",
          label: "",
          type: "textarea",
          maxLength: 600,
          required: false,
          error: "",
        },
      ],
    },
    {
      title: "Step 2: Refine Speech",
      id: "refine",
      desc: "Tweak your speech for the perfect fit. Adjust the tone, add stories, or suggest any edits you want. Your speech will update instantly.",
      instruction: "Number of AI-powered revisions remaining:",
      prompts: [
        {
          title:
            "What changes would you like?",
          attr: "feedbackdescription",
          value: "",
          placeholder: "Please update the...",
          label: "",
          type: "textarea",
          minRows: 5,
          maxLength: 800,
          required: true,
          error: "",
        },
      ],
    },
  ],
};

export default obj;
