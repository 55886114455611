import icon from "../images/icons/parents.png";

const obj = {
  title: "Parents of the Groom",
  to: "/ai/wedding/groom-parents",
  api: "/ai/wedding",
  getApi: "/ai/wedding/groom-parents",
  historyType: "groomParents",
  toolType: "speech",
  buttonTitle1: "Draft my speech",
  buttonTitle2: "Refine my speech",
  textTitle: "Speech",
  output: {
    title: "Speech Preview",
    Icon: false,
    color: "blue",
  },
  icon: icon,
  prompts: [
    {
      title: "Step 1: Provide Key Information",
      id: "information",
      desc: "Answer questions about the couple to get a custom-written draft toast. Share fun stories and specific details for the best results.",
      instruction: `<!-- <h1>Craft a memorable speech in just a few easy steps:</h1> --> 
      <li>Get your draft: Answer questions.</li> 
      <li>Make it yours: Give feedback or tap speech to edit.</li> 
      <li>Ready to share: Export for the big day.</li>`,
      prompts: [
        {
          title: "Father of the Groom's name",
          attr: "name",
          value: "",
          //placeholder: "The Father of the Groom’s name is...",
          label: "",
          type: "text",
          maxLength: 40,
          required: false,
          error: "",
        },
        {
          title: "Mother of the Groom's name",
          attr: "motherName",
          value: "",
          //placeholder: "The Mother of the Groom’s name is...",
          label: "",
          type: "text",
          maxLength: 40,
          required: false,
          error: "",
        },
        {
          title: "Groom’s name",
          attr: "groomName",
          value: "",
          //placeholder: "The Groom’s name is...",
          label: "",
          type: "text",
          maxLength: 40,
          required: true,
          error: "",
        },
        {
          title: "Groom's partner's name",
          attr: "partnerName",
          value: "",
          //placeholder: "His partner's name is...",
          label: "",
          type: "text",
          maxLength: 40,
          required: true,
          error: "",
        },
        {
          title: "Partner's parents",
          attr: "partnerParents",
          value: "",
          //placeholder: "Their names are...",
          label: "",
          type: "text",
          maxLength: 100,
          required: false,
          error: "",
        },
        {
          title: "What tone are you aiming for in your speech? (E.g. Heartfelt, funny, formal, or a blend)",
          attr: "tone",
          value: "",
          //placeholder: "I would like the speech to be...",
          //label:
            //"Example: lighthearted and humorous, sentimental and heartfelt",
          type: "text",
          maxLength: 100,
          required: true,
          error: "",
        },
        {
          title: "Three words that describe the Groom",
          attr: "groomWords",
          value: "",
          //placeholder: "The Groom is...",
          label: "",
          type: "text",
          maxLength: 100,
          required: true,
          error: "",
        },
        {
          title: "Describe a memorable moment from the Groom's childhood",
          attr: "groomMemories",
          value: "",
          //placeholder: "A memorable moment...",
          label: "",
          type: "textarea",
          maxLength: 400,
          required: false,
          error: "",
        },
        {
          title:
            "Share a memory that demonstrates the groom's best qualities",
          attr: "groomEvent",
          value: "",
          //placeholder: "A significant event...",
          label: "",
          type: "textarea",
          maxLength: 400,
          required: false,
          error: "",
        },
        {
          title:
            "How has the Groom brought joy into your life? What makes you proud of him?",
          attr: "groomAdmire",
          value: "",
          //placeholder: "The Groom has...",
          label: "",
          type: "textarea",
          maxLength: 400,
          required: false,
          error: "",
        },
        {
          title:
            "Is there a special accomplishment or moment in the Groom's life you'd like to acknowledge?",
          attr: "groomAchievement",
          value: "",
          //placeholder: "A significant achievement...",
          label: "",
          type: "textarea",
          maxLength: 400,
          required: false,
          error: "",
        },
        {
          title: "Three words that describe the Groom's partner",
          attr: "partnerWords",
          value: "",
          //placeholder: "The Groom's partner is...",
          label: "",
          type: "textarea",
          maxLength: 400,
          required: true,
          error: "",
        },
        {
          title:
            "Describe his partner's best qualities, and how do they bring out the best in him?",
          attr: "partnerImpact",
          value: "",
          //placeholder: "The Groom's partner has...",
          label: "",
          type: "textarea",
          maxLength: 400,
          required: false,
          error: "",
        },
        {
          title:
            "Why are the Groom and his partner such a great match? What's a moment that showed you this?",
          attr: "coupleAdmire",
          value: "",
          //placeholder: "I admire...",
          label: "",
          type: "textarea",
          maxLength: 400,
          required: false,
          error: "",
        },
        {
          title:
            "What are your hopes for the couple's life together?",
          attr: "coupleStrength",
          value: "",
          //placeholder: "A unique or special feature of the couple is...",
          label: "",
          type: "textarea",
          maxLength: 400,
          required: false,
          error: "",
        },
        //{
          //title:
            //"Are there any cultural or personal traditions that the newlyweds will be incorporating into the wedding or marriage?",
          //attr: "culturalTradition",
          //value: "",
          //placeholder: "The couple are...",
          //label: "If 'none', leave blank.",
          //type: "textarea",
          //maxLength: 400,
          //required: false,
          //error: "",
        //},
        //{
          //title:
            //"Is there anyone else that you would like to acknowledge or thank in your speech, such as close friends, family members, or wedding guests?",
          //attr: "speechThank",
          //value: "",
          //placeholder: "I would like to...",
          //label: "",
          //type: "textarea",
          //maxLength: 400,
          //required: true,
          //error: "",
        //},
        {
          title:
            "Is there anything else you would like to include in your speech?",
          attr: "coupleWords",
          value: "",
          //placeholder: "I would like to...",
          label: "",
          type: "textarea",
          maxLength: 600,
          required: false,
          error: "",
        },
      ],
    },
    {
      title: "Step 2: Refine Speech",
      id: "refine",
      desc: "Tweak your speech for the perfect fit. Adjust the tone, add stories, or suggest any edits you want. Your speech will update instantly.",
      instruction: "Number of automated revisions remaining:",
      prompts: [
        {
          title:
            "What changes would you like?",
          attr: "feedbackdescription",
          value: "",
          //placeholder: "Please update the...",
          label: "",
          type: "textarea",
          minRows: 5,
          maxLength: 800,
          required: true,
          error: "",
        },
      ],
    },
  ],
};

export default obj;
