import bestMan from "./best-man";
import father from "./father";
import groom from "./groom";
import maid from "./maid";
import bride from "./bride";
import groomParents from "./groom-parents";
import poem from "./poem";
import vows from "./vows";

const TOOLS = [bestMan, bride, father, groom, maid, groomParents, poem, vows];

export default TOOLS;
