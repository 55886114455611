import React, { Component } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

class Footer extends Component {
  render() {
    return (
      <div className="w-full bg-white">
        <div className="container mx-auto overflow-hidden px-4 py-4 md:px-28 md:py-8 lg:py-12 flex items-center flex-1 flex-wrap">
          <FooterLeft>
            {/*Watch our step-by-step{" "}
            <Link className="underline inline-flex" to="/tutorial">
              video guide
            </Link>{" "}
            to create your personalized speech
            <br />*/} Need assistance?{" "}
            <a
              className="underline inline-flex"
              href="mailto:support@wordwell.ai"
            >
              Click here{" "}
            </a>{" "}
            to contact our friendly support team
          </FooterLeft>
          <FooterRight>
            &copy; {new Date().getFullYear()} Wordwell AI. All rights reserved.
          </FooterRight>
        </div>
      </div>
    );
  }
}

const FooterLeft = styled.div`
  flex: 0 0 50%;

  @media (max-width: 767px) {
    flex: 0 0 100%;
  }
`;

const FooterRight = styled.div`
  flex: 0 0 50%;
  text-align: right;

  @media (max-width: 767px) {
    flex: 0 0 100%;
    text-align: left;
  }
`;

export default Footer;
