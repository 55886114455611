import React, { Component } from "react";
import { NavLink } from "react-router-dom";

import { LockClosedIcon } from "@heroicons/react/24/outline";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import { observable, makeObservable } from "mobx";

import { observer, inject } from "mobx-react";

import Logo from "../images/wordwell-logo.png";
import { createNotification } from "../Core/Notification";

@inject("store")
@observer
class Forgot extends Component {
  @observable email = "";
  @observable errorMessage = "";
  @observable isDisabled = false;

  constructor() {
    super();
    makeObservable(this);
  }

  onChange = (val, attr) => {
    this[attr] = val;
    this.errorMessage = "";
  };

  onReset = async (e) => {
    try {
      e.preventDefault();
      createNotification(
        "info",
        "Processing",
        "Please wait. OTP email sent. Check junk folder if not in inbox."
      );
      this.isDisabled = true;
      if (!this.email) {
        this.errorMessage = "Please submit email address!";
        createNotification("danger", "Error!", this.errorMessage);
        this.isDisabled = false;
      } else {
        let data = await this.props.store.api
          .post("/auth/forgot", {
            email: this.email,
          })
          .then(({ data }) => data);
        this.isDisabled = false;
        if (data.status === "success") {
          this.props.history.push({
            pathname: "/reset",
            state: { email: this.email },
          });
        } else if (data.status === "notExists") {
          this.props.history.push("/signup");
        }
      }
    } catch (err) {
      this.isDisabled = false;
      console.log(err);
      console.log(err?.response?.data?.message);
      if (err?.response?.data?.message) {
        this.errorMessage = err?.response?.data?.message;
        createNotification("danger", "Error!", this.errorMessage);
      }
    }
  };

  render() {
    return (
      <>
        <Helmet>
          <title>{`Reset - Prepare and create your own OpenAI Prompts and Outputs`}</title>
        </Helmet>
        <div className="container mx-auto lg:px-4 py-4 min-h-screen flex flex-col md:items-center md:justify-center">
          <div className="text-center mb-6">
            <img className="inline" src={Logo} width={250} alt="logo" />
          </div>

          <div
            className={`min-w-full md:min-w-0 bg-white rounded-xl shadow-xl transform transition-all transition shadow-md hover:shadow-2xl focus:shadow-2xl w-1/2`}
          >
            <div className="px-4 py-4 md:px-12 md:py-12">
              <ResetBlock
                email={this.email}
                onReset={this.onReset}
                onChange={this.onChange}
                isDisabled={this.isDisabled}
              />
            </div>
            <a
              href={`https://www.wordwell.ai/`}
              className="block text-center bg-gray-100  text-sm p-3 rounded-b-lg hover:bg-gray-200 cursor-pointer"
            >
              Back to Home Page
            </a>
          </div>
        </div>
      </>
    );
  }
}

const ResetBlock = observer(({ email, onReset, onChange, isDisabled }) => {
  return (
    <>
      <form onSubmit={onReset}>
        <div
          className={`mx-auto flex-shrink-0 flex items-center justify-center h-16 w-16 rounded-full bg-${
            email ? "green" : "gray"
          }-300  ${email ? "bg-green-300" : "bg-gray-300"} `}
        >
          <LockClosedIcon
            className={`h-8 w-8 text-${email ? "green-700" : "gray-500"}`}
            aria-hidden="true"
          />
        </div>
        <div className="mt-3 text-center ">
          <div className="text-3xl font-medium ">Reset</div>
          <p className="text-lg ">Enter your Email Address</p>
          <div className="flex flex-col flex-1">
            <label className=" text-sm block mt-4 inline-block text-left">
              Email Address
            </label>
            <input
              value={email}
              onChange={(e) => onChange(e.target.value, "email")}
              focus="true"
              type="email"
              className="rounded-md text-lg px-4 py-2 border border-gray-300 "
              placeholder="john@smith.com"
            />
          </div>
          <div className="flex flex-col">
            <button
              disabled={isDisabled}
              type="submit"
              className={`hover:bg-gray-600 font-medium rounded-lg text-lg px-4 py-2 bg-gray-500 text-white mt-4 border border-gray-300 inline-block ${
                isDisabled && "pointer-events-none opacity-25"
              }`}
            >
              Request One-Time Password
            </button>
            <NavLink to="/login" className="mt-4  text-sm">
              Login to your account
            </NavLink>
          </div>
        </div>
      </form>
    </>
  );
});

export default withRouter(Forgot);
