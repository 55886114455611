import React, { Component } from "react";
import { CheckCircleIcon, UserCircleIcon } from "@heroicons/react/24/solid";

import Logo from "./images/wordwell-logo.png";
import config from "./config";

import { observer, inject } from "mobx-react";
@inject("store")
@observer
class Pricing extends Component {
  render() {
    const redirect = new URLSearchParams(window.location.search).get(
      "redirect"
    );
    const type = new URLSearchParams(window.location.search).get("type");
    return (
      <>
        {this.props.store.profile.status === "active" ? null : (
          <div className="border-b border-gray-300 bg-white shadow-sm ">
            <div className="container flex mx-auto px-4 md:px-28 flex select-none">
              <div className="relative  focus-within:text-green-500 flex flex-1 "></div>
              <div
                onClick={this.props.store.handleLogout}
                className="cursor-pointer text-lg flex py-3 px-6 xl:py-4 xl:px-8 hover:bg-gray-100 rounded-t-md font-medium transition items-center"
              >
                <UserCircleIcon className="w-7 h-7 lg:mr-4 transition" />
                <div className="hidden lg:block"> Log Out</div>
              </div>
            </div>
          </div>
        )}

        <div
          className={`container mx-auto select-none ${
            this.props.store.profile.status === "active"
              ? ""
              : "px-8 py-4 lg:px-28 lg:py-12 lg:pb-64"
          }`}
        >
          {this.props.store.profile.status === "active" ? (
            <>
              <p className="text-lg sm:text-xl md:text-2xl  mb-14 mt-2 text-center">
                {this.props.store.profile.plan === "tbc"
                  ? "Upgrade now to instantly access your full speech/vows and additional features to refine your speech."
                  : "Upgrade to unlock additional features and revisions."}
                <br />
                All prices are listed in US dollars.
              </p>
            </>
          ) : (
            <>
              <div className="text-center">
                <img className="inline" src={Logo} width={250} alt="logo" />
              </div>
              <p className="text-lg sm:text-xl md:text-2xl  mb-14 mt-2 text-center">
                Pick a pricing option to begin creating your speech.
                <br />
                All prices are listed in US dollars.
                <br />
                Purchase risk-free with our 100% money-back satisfaction
                guarantee.
              </p>
            </>
          )}

          <Grid>
            {/* {this.props.store.profile.status === "inactive" ? (
              <Tbc
                fromColor="gray-400"
                toColor="gray-500"
                baseURL={this.props.store.baseURL}
                api={this.props.store.api}
              />
            ) : null} */}
            {this.props.store.profile.status === "inactive" ||
            this.props.store.profile.plan === "tbc" ? (
              <Essential
                fromColor="green-400"
                toColor="green-600"
                baseURL={this.props.store.baseURL}
                api={this.props.store.api}
                plan={this.props.store.profile.plan}
                redirect={redirect}
              />
            ) : null}
            {this.props.store.profile.status === "inactive" ||
            this.props.store.profile.plan === "tbc" ||
            this.props.store.profile.plan === "entry" ? (
              <Premium
                fromColor="indigo-500"
                toColor="red-500"
                baseURL={this.props.store.baseURL}
                api={this.props.store.api}
                plan={this.props.store.profile.plan}
                redirect={redirect}
              />
            ) : null}
          </Grid>
        </div>
      </>
    );
  }
}

const Tbc = ({ fromColor, toColor, baseURL, api }) => (
  <div className="flex relative order-1 xl:order-1">
    <div
      className={`bg-white rounded-xl transition hover:shadow-md overflow-hidden md:max-w-1lg  border-t-2 border- hover:border-${
        fromColor ? fromColor : "blue-400"
      } md:flex relative transform hover:scale-105   flex-1`}
    >
      <div className="p-5 flex-1">
        <div className="flex flex-col h-full justify-between">
          <div>
            <div
              href="#"
              className={`text-${
                fromColor ? fromColor : "green-500"
              } block text-lg text-2xl leading-tight font-medium mb-2`}
            >
              Free Trial
            </div>
            <div className="text-5xl  font-bold">FREE</div>

            <div className="divide-y divide-dashed divide-gray-300 mt-4">
              <div className="py-2 flex  items-center">
                <CheckCircleIcon
                  className={`w-6 h-6 mr-2 text-${
                    fromColor ? fromColor : "green-500"
                  }`}
                  style={{ flex: "0 0 24px" }}
                />
                <div>
                  Risk Free: Craft a stunning speech or heartfelt vows in
                  minutes
                </div>
              </div>
              <div className="py-2 flex  items-center">
                <CheckCircleIcon
                  className={`w-6 h-6 mr-2 text-${
                    fromColor ? fromColor : "green-500"
                  }`}
                  style={{ flex: "0 0 24px" }}
                />
                <div>Preview one-third of your speech or vows as a sample</div>
              </div>
              <div className="py-2 flex  items-center">
                <CheckCircleIcon
                  className={`w-6 h-6 mr-2 text-${
                    fromColor ? fromColor : "green-500"
                  }`}
                  style={{ flex: "0 0 24px" }}
                />
                <div>
                  Upgrade for full access and premium tools to polish your
                  speech
                </div>
              </div>
            </div>
          </div>

          <form
            action={baseURL + "user/stripe/tbc"}
            method="POST"
            className="flex "
          >
            <input
              type="hidden"
              name="token"
              value={api.defaults.headers.common["x-access-token"]}
            />
            <button
              type="submit"
              className={`mt-8 inset-0 bg-gradient-to-r from-${
                fromColor ? fromColor : "green-400"
              } to-${
                toColor ? toColor : "blue-500"
              } shadow-lg flex-1 rounded-md p-4 text-white font-medium text-center text-lg transition hover:from-gray-700 hover:to-gray-800 text-enter`}
            >
              Try Now
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
);

const Essential = ({ fromColor, toColor, baseURL, api, plan, redirect }) => (
  <div className="flex relative order-1 xl:order-1">
    <div
      className="absolute bg-gradient-to-r from-green-400 to-green-600 shadow-lg text-white text-sm font-medium text-center rounded-full px-2 py-1 inline-block"
      style={{
        top: "-15px",
        left: "50%",
        transform: "translate(-50%, 0px)",
        zIndex: "9",
        width: "150px",
      }}
    >
      Most popular plan
    </div>
    <div
      className={`bg-white rounded-xl transition hover:shadow-md overflow-hidden md:max-w-1lg  border-t-2 border- hover:border-${
        fromColor ? fromColor : "blue-400"
      } md:flex relative transform hover:scale-105   flex-1`}
    >
      <div className="p-5 flex-1">
        <div className="flex flex-col h-full justify-between">
          <div>
            <div
              href="#"
              className={`text-${
                fromColor ? fromColor : "green-500"
              } block text-lg text-2xl leading-tight font-medium mb-2`}
            >
              Essentials
            </div>
            <div className="text-5xl  font-bold">$14</div>

            <div className="divide-y divide-dashed divide-gray-300 mt-4">
              <div className="py-2 flex  items-center">
                <CheckCircleIcon
                  className={`w-6 h-6 mr-2 text-${
                    fromColor ? fromColor : "green-500"
                  }`}
                  style={{ flex: "0 0 24px" }}
                />
                <div>
                  Personalized wedding speech{" "}
                  <span className="font-medium ">OR</span> vows
                </div>
              </div>
              <div className="py-2 flex  items-center">
                <CheckCircleIcon
                  className={`w-6 h-6 mr-2 text-${
                    fromColor ? fromColor : "green-500"
                  }`}
                  style={{ flex: "0 0 24px" }}
                />
                <div className="font-medium ">1 AI-powered re-write</div>
              </div>
              <div className="py-2 flex  items-center">
                <CheckCircleIcon
                  className={`w-6 h-6 mr-2 text-${
                    fromColor ? fromColor : "green-500"
                  }`}
                  style={{ flex: "0 0 24px" }}
                />
                <div>Unlimited manual edits</div>
              </div>
              <div className="py-2 flex  items-center">
                <CheckCircleIcon
                  className={`w-6 h-6 mr-2 text-${
                    fromColor ? fromColor : "green-500"
                  }`}
                  style={{ flex: "0 0 24px" }}
                />
                <div>One-click download</div>
              </div>
              <div className="py-2 flex  items-center">
                <CheckCircleIcon
                  className={`w-6 h-6 mr-2 text-${
                    fromColor ? fromColor : "green-500"
                  }`}
                  style={{ flex: "0 0 24px" }}
                />
                <div>Ultimate Wedding Speech & Delivery Guidebook</div>
              </div>
            </div>
          </div>

          <form
            action={baseURL + "user/stripe/payment"}
            method="POST"
            className="flex "
          >
            <input
              type="hidden"
              name="token"
              value={api.defaults.headers.common["x-access-token"]}
            />
            <input
              type="hidden"
              name="priceId"
              value={config.stripe.essentials}
            />
            <input type="hidden" name="redirect" value={redirect} />
            {/* <input type="hidden" name="trial" value="true" /> */}
            <button
              type="submit"
              className={`mt-8 inset-0 bg-gradient-to-r from-${
                fromColor ? fromColor : "green-400"
              } to-${
                toColor ? toColor : "blue-500"
              } shadow-lg flex-1 rounded-md p-4 text-white font-medium text-center text-lg transition hover:from-gray-700 hover:to-gray-800 text-enter`}
            >
              {plan === "tbc" ? "Upgrade Now" : "Get Started"}
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
);

const Premium = ({ fromColor, toColor, baseURL, api, plan, redirect }) => (
  <div className="flex relative order-2 xl:order-2">
    <div
      className={`bg-white rounded-xl transition hover:shadow-md overflow-hidden md:max-w-1lg  border-t-2 border- hover:border-${
        fromColor ? fromColor : "blue-400"
      } md:flex relative transform hover:scale-105   flex-1`}
    >
      <div className="p-5 flex-1">
        <div className="flex flex-col h-full justify-between">
          <div>
            <div
              href="#"
              className={`text-${
                fromColor ? fromColor : "green-500"
              } block text-lg text-2xl leading-tight font-medium mb-2`}
            >
              Premium
            </div>
            <div className="text-5xl  font-bold">
              {plan === "entry" ? "$5" : "$19"}
            </div>

            <div className="divide-y divide-dashed divide-gray-300 mt-4">
              <div className="py-2 flex  items-center">
                <CheckCircleIcon
                  className={`w-6 h-6 mr-2 text-${
                    fromColor ? fromColor : "green-500"
                  }`}
                  style={{ flex: "0 0 24px" }}
                />
                <div>
                  Personalized wedding speech{" "}
                  <span className="font-medium ">AND</span> vows
                </div>
              </div>
              <div className="py-2 flex  items-center">
                <CheckCircleIcon
                  className={`w-6 h-6 mr-2 text-${
                    fromColor ? fromColor : "green-500"
                  }`}
                  style={{ flex: "0 0 24px" }}
                />
                <div className="font-medium ">
                  A bespoke poem or card message
                </div>
              </div>
              <div className="py-2 flex  items-center">
                <CheckCircleIcon
                  className={`w-6 h-6 mr-2 text-${
                    fromColor ? fromColor : "green-500"
                  }`}
                  style={{ flex: "0 0 24px" }}
                />
                <div className="font-medium ">
                  2 AI-powered re-writes per item (speech, vows)
                </div>
              </div>
              <div className="py-2 flex  items-center">
                <CheckCircleIcon
                  className={`w-6 h-6 mr-2 text-${
                    fromColor ? fromColor : "green-500"
                  }`}
                  style={{ flex: "0 0 24px" }}
                />
                <div>Unlimited manual edits</div>
              </div>
              <div className="py-2 flex  items-center">
                <CheckCircleIcon
                  className={`w-6 h-6 mr-2 text-${
                    fromColor ? fromColor : "green-500"
                  }`}
                  style={{ flex: "0 0 24px" }}
                />
                <div>One-click download</div>
              </div>
              <div className="py-2 flex  items-center">
                <CheckCircleIcon
                  className={`w-6 h-6 mr-2 text-${
                    fromColor ? fromColor : "green-500"
                  }`}
                  style={{ flex: "0 0 24px" }}
                />
                <div>Ultimate Wedding Speech & Delivery Guidebook</div>
              </div>
            </div>
          </div>
          <form
            action={baseURL + "user/stripe/payment"}
            method="POST"
            className="flex"
          >
            <input
              type="hidden"
              name="token"
              value={api.defaults.headers.common["x-access-token"]}
            />
            <input
              type="hidden"
              name="priceId"
              value={
                plan === "entry"
                  ? config.stripe.premiumUpgrade
                  : config.stripe.premium
              }
            />
            <input type="hidden" name="redirect" value={redirect} />
            <button
              type="submit"
              className={`mt-8 inset-0 bg-gradient-to-r from-${
                fromColor ? fromColor : "green-400"
              } to-${
                toColor ? toColor : "blue-500"
              } shadow-lg flex-1 rounded-md p-4 text-white font-medium text-center text-lg transition hover:from-gray-700 hover:to-gray-800 text-enter`}
            >
              {plan === "tbc" || plan === "entry"
                ? "Upgrade Now"
                : "Get Started"}
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
);

const Grid = ({ children }) => (
  <div className="grid grid-cols-1 gap-6 mt-4 xl:grid-cols-3">{children}</div>
);

export default Pricing;
