import React, { Component } from "react";

import { observer, inject } from "mobx-react";
@inject("store")
@observer
class Tutorial extends Component {
  render() {
    return (
      <div
        className={`container mx-auto select-none px-8 py-4 lg:px-28 lg:py-12 lg:pb-64`}
      >
        <h2 className="text-xl text-center sm:text-2xl md:text-3xl ">
          Platform Tutorial
        </h2>
        <p className="text-lg  mb-10 mt-2 text-center">
          Check out our 3-minute video guide, which covers everything you need
          to know to create the perfect toast. The guide is simple and easy to
          follow, and will help you get the most out of our app.
        </p>
        <div
          style={{
            padding: "56.25% 0 0 0",
            position: "relative",
          }}
        >
          <iframe
            src="https://player.vimeo.com/video/812464861?h=70906f9db8&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
            title="Wordwell AI Video Guide"
          ></iframe>
        </div>
      </div>
    );
  }
}

export default Tutorial;
