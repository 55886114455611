import React, { Component } from "react";
import {
  PencilIcon,
  ClockIcon,
  DocumentDuplicateIcon,
} from "@heroicons/react/24/outline";

import { Helmet } from "react-helmet";
import Header from "../Components/Header";
import Body, { Grid, Col } from "../Components/Body";
import Button from "../Components/Button";
import Output from "../Components/Output";
import Countdown from "react-countdown";
import { withRouter } from "react-router-dom";

import { observable, makeObservable, computed, action } from "mobx";
import { observer, inject } from "mobx-react";

import EntryTabs from "../Components/EntryTabs";
import EntryPrompt from "../Components/EntryPrompt";
import EntryInput from "../Components/EntryInput";
import EntryN from "../Components/EntryN";

import Filter from "bad-words";

import jsPDF from "jspdf";
import { createNotification } from "../Core/Notification";
import styled from "styled-components";
import socketIOClient from "socket.io-client";

let filterBadWords = new Filter();

@inject("store")
@observer
class Tool extends Component {
  @observable tool = {};
  @observable initialData = {};

  @observable.deep prompts = [];
  @observable currentPrompt = 0;
  @observable currentOption = "Create Speech";

  @observable error = "";

  @observable output = "";
  @observable outputAll = [];
  @observable currentOutput = -1;
  @observable outputs = [];
  @observable code = "";

  @observable loading = false;
  @observable isTypingDone = false;
  @observable isOutputEditing = false;
  @observable canRemoveTypist = false;
  @observable oldOutput = "";
  @observable isFirstFeedbackDone = false;
  @observable isInitialDataloading = true;

  @observable isSocketDataOk = false;
  @observable socketData = "";

  @observable revisions = 0;
  @observable addonRevisions = 0;

  @observable date = Date.now() + 1000;
  countdown = [];

  constructor(props) {
    super(props);
    makeObservable(this);
    this.tool = this.props.store.getToolByUrl(this.props.location.pathname);
    if (!this.tool) {
      window.location.href = "/";
    } else {
      this.prompts = [...this.tool.prompts];
      this.revisions = this.props.store.profile.revisions;
      this.addonRevisions = this.props.store.profile.addonRevisions;
      this.loadData();
      const socket = socketIOClient(process.env.REACT_APP_SOCKET_BASEURL);
      socket.on("connect", () => {
        socket.emit("join", {
          email: this.props.store.profile.email,
          historyType: this.tool.historyType,
          isLoading: this.loading,
          userId: this.props.store.profile._id,
        });
        socket.on("outputDone", (data) => {
          console.log("outputDone");
          if (this.loading) {
            console.log("outputDone loading");
            if (this.prompts[this.currentPrompt].id === "refine") {
              if (
                this.props.store.profile.plan === "pro" &&
                this.tool.toolType === "addon"
              ) {
                this.addonRevisions = this.addonRevisions - 1;
              } else {
                this.revisions = this.revisions - 1;
              }
            }

            if (data) {
              this.output = this.checkOutput(data);
              this.outputAll[this.outputAll.length] = this.output;
              this.currentOutput = this.outputAll.length - 1;
            }

            this.date = Date.now() + 10000;
            this.countdown.forEach((countdown) => {
              if (countdown) {
                countdown.stop();
                countdown.start();
              }
            });
            this.loading = false;
          }
        });
        socket.on("outputFailed", (data) => {
          console.log("outputFailed");
          createNotification(
            "danger",
            "Error!",
            "No Content was generated, please try again"
          );
          this.loading = false;
        });
      });
    }
  }

  @action async loadData() {
    let response = await this.props.store.api.get(this.tool.getApi);
    if (response) {
      this.isInitialDataloading = false;
      if (response.data.data) {
        this.initialData = response.data.data;
        if (this.initialData.output) {
          this.output = this.initialData.output;
          this.outputAll[this.outputAll.length] = this.output;
          this.currentOutput = this.outputAll.length - 1;
          this.canRemoveTypist = true;
          this.isTypingDone = true;
        }
        this.prompts.forEach((p, pI) => {
          p.prompts.forEach((prompt, promptIndex) => {
            let isExists = this.initialData.data[prompt.attr];
            if (isExists) {
              this.prompts[pI].prompts[promptIndex].value = isExists;
            }
          });
        });
      }
    }
  }

  handleCurrentPrompt = (val) => {
    if (this.isTypingDone && !(this.props.store.profile.plan === "tbc")) {
      this.currentPrompt = val;
    }
  };

  @computed get isGenerateButtonDisabled() {
    if (this.loading) {
      return true;
    }

    return false;
  }

  @computed get disabled() {
    if (this.prompts[this.currentPrompt].prompts[0].value.length < 1) {
      return true;
    }

    return false;
  }

  @computed get isMinLength() {
    if (!this.props.prompt.min) {
      return false;
    }
    if (!this.props.prompt.type === "number") {
      return false;
    }

    return false;
  }

  checkMinimumPrompts = () => {
    let shouldReturn = false;

    this.prompts[this.currentPrompt].prompts.forEach((prompt, promptIndex) => {
      if (prompt.min) {
        if (prompt.value.length < prompt.min) {
          shouldReturn = true;
          prompt.error = `${prompt.title} needs to meet the minimum ${prompt.min} characters`;
        }
      }
    });

    return shouldReturn;
  };

  checkRequriedPrompts = () => {
    let shouldReturn = false;

    this.prompts[this.currentPrompt].prompts.forEach((prompt, promptIndex) => {
      if (prompt.required) {
        if (prompt.value.trim().length === 0) {
          shouldReturn = true;
          prompt.error = `This is a required field`;
        }
      }
    });

    return shouldReturn;
  };

  clearExampleTimeout = [];

  onStartUsing = async () => {
    this.loading = false;
    this.error = "";
    this.clearExampleTimeout.forEach((item, index) => {
      clearTimeout(this.clearExampleTimeout[index]);
    });
    this.currentOption = "Create Speech";
  };

  onExample = async () => {
    this.loading = true;
    this.error = "";
    this.output = "";
    this.outputs = [];
    this.code = ``;

    this.currentOption = "See An Example";

    let totalLength = 0;

    this.clearExampleTimeout.forEach((item, index) => {
      clearTimeout(this.clearExampleTimeout[index]);
    });

    this.prompts[this.currentPrompt].prompts.forEach((prompt, promptIndex) => {
      this.prompts[this.currentPrompt].prompts[promptIndex].value = "";
    });

    this.prompts[this.currentPrompt].prompts.forEach((prompt, promptIndex) => {
      for (
        let timeoutIndex = 0;
        timeoutIndex < prompt.example.length;
        timeoutIndex++
      ) {
        totalLength++;
        this.clearExampleTimeout[totalLength] = setTimeout(() => {
          this.prompts[this.currentPrompt].prompts[promptIndex].value +=
            prompt.example[timeoutIndex];
        }, 7 * totalLength);
      }
    });

    totalLength++;

    if (this.prompts[this.currentPrompt].example.output) {
      this.clearExampleTimeout[totalLength] = setTimeout(() => {
        this.output = this.prompts[this.currentPrompt].example.output;
        totalLength++;
        this.clearExampleTimeout[totalLength] = setTimeout(() => {
          this.loading = false;
          this.currentOption = "Create Speech";
          this.prompts[this.currentPrompt].prompts[0].value += " ";
        }, 7 * totalLength + this.prompts[this.currentPrompt].example.output.length * 7 + 500);
      }, 7 * totalLength + 500);
    }

    if (this.prompts[this.currentPrompt].example.code) {
      totalLength++;
      this.clearExampleTimeout[totalLength] = setTimeout(() => {
        this.code = `${this.prompts[this.currentPrompt].example.code}`;
        this.loading = false;
      }, 7 * totalLength + 500);
    }

    if (this.prompts[this.currentPrompt].example.outputs) {
      this.clearExampleTimeout[totalLength] = setTimeout(() => {
        this.outputs = this.prompts[this.currentPrompt].example.outputs;

        totalLength++;
        this.clearExampleTimeout[totalLength] = setTimeout(() => {
          this.loading = false;
          this.currentOption = "Create Speech";
          // this.prompts[this.currentPrompt].prompts[0].value += " "
        }, 7 * totalLength + 500);
      }, 7 * totalLength + 500);
    }
  };

  sanitizeAllPrompts = () => {
    this.prompts[this.currentPrompt].prompts.forEach((prompt) => {
      if (!prompt.value) {
        return false;
      }
      if (prompt.type === "number") {
        return false;
      }

      prompt.value = prompt.value.trim();

      if (filterBadWords.isProfane(prompt.value)) {
        prompt.error = "Unsafe content , please try different language";
        throw Error("Unsafe content");
      }
    });
  };

  contentFilterFlagged = async (response) => {
    this.error = response.message;

    this.date = Date.now() + 5000;
    this.countdown.forEach((countdown) => {
      if (countdown) {
        countdown.stop();
        countdown.start();
      }
    });
    this.loading = false;
  };

  checkOutput = (output) => {
    if (output) {
      output = output.replace(/^\s+|\s+$/g, "");
    }
    return output;
  };

  @computed get language() {
    let language = "";
    this.prompts[this.currentPrompt].prompts.forEach((prompt) => {
      if (prompt.attr === "language") {
        language = `${prompt.value}`;
      }
    });
    return language;
  }

  onGenerateClick = async () => {
    try {
      console.log("postObj");
      this.error = "";
      this.output = "";
      this.code = ``;
      this.outputs = [];
      this.loading = true;
      this.isTypingDone = false;
      this.canRemoveTypist = false;

      let checkMinimumPrompts = this.checkMinimumPrompts();
      if (checkMinimumPrompts) {
        this.loading = false;
        return false;
      }

      let checkRequriedPrompts = this.checkRequriedPrompts();
      if (checkRequriedPrompts) {
        this.loading = false;
        return false;
      }

      this.sanitizeAllPrompts();

      let postObj = {};

      postObj.data = {};
      this.prompts[this.currentPrompt].prompts.forEach((prompt) => {
        postObj.data[prompt.attr] = prompt.value;
      });

      postObj.currentPromptId = this.prompts[this.currentPrompt].id;
      postObj.historyType = this.tool.historyType;
      postObj.toolType = this.tool.toolType;
      postObj.title = this.tool.title;
      if (this.prompts[this.currentPrompt].id === "refine") {
        this.isFirstFeedbackDone = true;
      }

      let response = await this.props.store.api.post(this.tool.api, postObj);

      if (!response.data.success) {
        createNotification(
          "danger",
          "Error!",
          "No Content was generated, please try again"
        );
        this.contentFilterFlagged(response.data);
        return false;
      }
    } catch (error) {
      console.log(error);
      this.countdown.forEach((countdown) => {
        if (countdown) {
          countdown.stop();
          countdown.start();
        }
      });
      this.loading = false;
    }
  };

  onTypingDone = () => {
    this.isTypingDone = true;
    this.canRemoveTypist = true;
  };

  printDocument = async () => {
    try {
      createNotification("info", "Processing", "Please wait!");
      let htmlElement = document.getElementById("divToPrint");

      let pdf = new jsPDF("p", "pt");

      let fileName = "speech.pdf";

      if (this.tool.historyType === "poem") {
        fileName = "message-or-poem.pdf";
      } else if (this.tool.historyType === "vows") {
        fileName = "wedding-vows.pdf";
      }

      pdf.html(htmlElement, {
        callback: function (pdf) {
          pdf.save(fileName);
          // window.open(pdf.output("bloburl"));
        },
        autoPaging: "text",
        margin: 20,
        html2canvas: { scale: 1 },
      });

      let postObj = {
        historyType: this.tool.historyType,
      };

      await this.props.store.api.post("/ai/wedding/draftReady", postObj);
    } catch (error) {
      console.log(error);
    }
  };

  mailDocument = async () => {
    try {
      createNotification("info", "Processing", "Please wait!");
      let postObj = {
        output: this.output,
        historyType: this.tool.historyType,
      };

      let response = await this.props.store.api.post(
        "/ai/wedding/email",
        postObj
      );

      if (response.data.status === "success") {
        createNotification(
          "success",
          "Success!",
          "Mail has been sent to your account!"
        );
        let postObj = {
          historyType: this.tool.historyType,
        };

        await this.props.store.api.post("/ai/wedding/draftReady", postObj);
      }

      if (!response.data.success) {
        return false;
      }
    } catch (error) {
      console.log(error);
    }
  };

  onOutputClick = () => {
    if (!this.isOutputEditing && this.isTypingDone) {
      this.isOutputEditing = true;
      this.oldOutput = this.output;
    }
  };

  updateOutput = () => {
    this.isOutputEditing = false;
  };

  cancelUpdateOutput = () => {
    this.isOutputEditing = false;
    this.output = this.oldOutput;
  };

  onOutputChange = async (e) => {
    this.output = e.target.value;
    this.outputAll[this.currentOutput + 1] = this.output;
    console.log(this.outputAll);
  };

  doUndo = () => {
    if (this.currentOutput > 0) {
      this.output = this.outputAll[this.currentOutput - 1];
      this.currentOutput = this.currentOutput - 1;
    }
  };

  doRedo = () => {
    if (this.currentOutput < this.outputAll.length - 1) {
      this.output = this.outputAll[this.currentOutput + 1];
      this.currentOutput = this.currentOutput + 1;
    }
  };

  render() {
    // required for mobx to pick up deeply nested value
    const currentValue = this.prompts[this.currentPrompt].prompts[0].value;

    return (
      <>
        <Helmet>
          <title>{`${this.tool.title} Tool - Prepare and create your own OpenAI Prompts and Outputs`}</title>
        </Helmet>
        <Header
          title={this.tool.title}
          desc={this.tool.desc}
          Icon={this.tool.Icon}
          fromColor={this.tool.fromColor}
          category={this.tool.category}
        />
        <Body>
          {this.prompts.map((prompt, index) => (
            <>
              <div
                className={`text-lg  flex items-center p-6 ${
                  this.currentPrompt === index ? "" : "hidden"
                }`}
                key={index}
              >
                {this.currentPrompt === 0 && (
                  <Instructions
                    dangerouslySetInnerHTML={{ __html: prompt.instruction }}
                  />
                )}
                {this.currentPrompt !== 0 && prompt.instruction}
                {this.currentPrompt === 1 && (
                  <>
                    <span className="text-3xl pl-2 font-medium ">
                      {this.props.store.profile.plan === "pro" &&
                      this.tool.toolType === "addon"
                        ? this.addonRevisions
                        : this.revisions}
                    </span>
                  </>
                )}
              </div>
            </>
          ))}
          <Grid>
            <Col span="6" className="relative">
              <EntryTabs
                prompts={this.prompts}
                currentPrompt={this.currentPrompt}
                onChange={this.handleCurrentPrompt}
                isTypingDone={this.isTypingDone}
              />
              {this.prompts.map((prompt, index) => (
                <EntryPrompt
                  prompt={prompt}
                  key={index}
                  index={index}
                  disabled={this.disabled}
                  currentPrompt={this.currentPrompt}
                >
                  {prompt.prompts.map((promptInput, index) => (
                    <EntryInput
                      prompt={promptInput}
                      key={index}
                      language={this.language}
                      index={index}
                      disabled={this.disabled}
                      isFirstFeedbackDone={this.isFirstFeedbackDone}
                    />
                  ))}
                  <div className="md:flex">
                    <Countdown
                      ref={(countdown) => (this.countdown[index] = countdown)}
                      date={this.date}
                      renderer={(props) =>
                        index === 0 ? (
                          <Button
                            title={
                              props.total
                                ? `Timeout ${props.total / 1000} secs`
                                : this.tool.buttonTitle1
                            }
                            disabled={
                              props.total ||
                              this.isGenerateButtonDisabled ||
                              this.props.store.profile.keyRevisions <= 0
                            }
                            Icon={
                              props.total
                                ? ClockIcon
                                : currentValue
                                ? DocumentDuplicateIcon
                                : PencilIcon
                            }
                            onClick={this.onGenerateClick}
                          />
                        ) : (
                          <Button
                            title={
                              props.total
                                ? `Timeout ${props.total / 1000} secs`
                                : this.tool.buttonTitle2
                            }
                            disabled={
                              props.total ||
                              this.isGenerateButtonDisabled ||
                              (this.props.store.profile.plan === "pro" &&
                                this.tool.toolType === "addon" &&
                                this.props.store.profile.addonRevisions <= 0) ||
                              (this.props.store.profile.plan === "pro" &&
                                this.tool.toolType === "speech" &&
                                this.props.store.profile.revisions <= 0) ||
                              (this.props.store.profile.plan !== "pro" &&
                                this.props.store.profile.revisions <= 0)
                            }
                            Icon={
                              props.total
                                ? ClockIcon
                                : currentValue
                                ? DocumentDuplicateIcon
                                : PencilIcon
                            }
                            onClick={this.onGenerateClick}
                          />
                        )
                      }
                    />
                    <EntryN
                      prompts={this.prompts}
                      currentPrompt={this.currentPrompt}
                    />
                  </div>
                  <div className="hidden xl:block mt-2 text-xs  ">
                    {index === 0 &&
                      `Return to top of page to view ${
                        this.tool.toolType === "speech" ? "Speech" : ""
                      } Preview.`}
                  </div>
                  <div className="block xl:hidden mt-2 text-xs  ">
                    Keep your device unlocked and
                    remain on this page while your{" "}
                    {this.tool.toolType === "speech" ? "speech" : ""} is generating.
                  </div>
                  {this.error && (
                    <div className="mt-4">
                      <label
                        className={`${
                          this.error ? "text-red-400" : ""
                        } font-medium transition-all`}
                      >
                        {this.error}
                      </label>
                    </div>
                  )}
                </EntryPrompt>
              ))}
            </Col>
            <Col span="6">
              <Output
                title={this.tool.output.title}
                textTitle={this.tool.textTitle}
                speechTitle={this.tool.title}
                desc={this.tool.output.desc}
                Icon={this.tool.output.Icon || this.tool.Icon}
                fromColor={this.tool.fromColor}
                toColor={this.tool.toColor}
                loading={this.loading}
                output={this.output}
                outputs={this.outputs}
                code={this.code}
                language={this.language}
                outputsColor={this.tool.output.color}
                OutputsIcon={this.tool.output.Icon}
                pdfTitle={this.prompts[0].prompts[0].value}
                onTypingDone={this.onTypingDone}
                isTypingDone={this.isTypingDone}
                printDocument={this.printDocument}
                mailDocument={this.mailDocument}
                onOutputClick={this.onOutputClick}
                isOutputEditing={this.isOutputEditing}
                canRemoveTypist={this.canRemoveTypist}
                updateOutput={this.updateOutput}
                cancelUpdateOutput={this.cancelUpdateOutput}
                onOutputChange={this.onOutputChange}
                doUndo={this.doUndo}
                doRedo={this.doRedo}
                plan={this.props.store.profile.plan}
                historyType={this.tool.historyType}
                to={this.tool.to}
                toolType={this.tool.toolType}
              />
            </Col>
          </Grid>
        </Body>
      </>
    );
  }
}

export default withRouter(Tool);

const Instructions = styled.div`
  h1 {
    font-weight: 600;
  }
`;
